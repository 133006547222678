import Box from '@mui/material/Box';
import backgroundImg from './assets/label-rage-mode.png';

export const ProgressBarRageMode = () => {
  return (
    <Box sx={{ position: 'absolute', width: 642, height: 173, left: 0, top: -44 }}>
      <img src={backgroundImg} style={{ width: '100%', height: '100%' }} />
    </Box>
  );
};

export default ProgressBarRageMode;
