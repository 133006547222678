import type { IGameConfig } from 'types';
import symbol_a from './assets/symbols/A.png';
import symbol_c from './assets/symbols/C.png';
import symbol_e from './assets/symbols/E.png';
import symbol_hp1 from './assets/symbols/HP1.png';
import symbol_hp2 from './assets/symbols/HP2.png';
import symbol_hp3 from './assets/symbols/HP3.png';
import symbol_hp4 from './assets/symbols/HP4.png';
import symbol_j from './assets/symbols/J.png';
import symbol_k from './assets/symbols/K.png';
import symbol_m from './assets/symbols/M.png';
import symbol_q from './assets/symbols/Q.png';
import symbol_sc1 from './assets/symbols/SC1.png';
import symbol_wl from './assets/symbols/WL.png';
import symbol_x from './assets/symbols/X.png';

enum symbols {
  A = 'A',
  C = 'C',
  E = 'E',
  J = 'J',
  K = 'K',
  M = 'M',
  Q = 'Q',
  X = 'X',
  SC1 = 'SC1',
  WL = 'WL',
  HP1 = 'HP1',
  HP2 = 'HP2',
  HP3 = 'HP3',
  HP4 = 'HP4',
}

const assets: Record<symbols, string> = {
  [symbols.A]: symbol_a,
  [symbols.C]: symbol_c,
  [symbols.E]: symbol_e,
  [symbols.J]: symbol_j,
  [symbols.K]: symbol_k,
  [symbols.M]: symbol_m,
  [symbols.Q]: symbol_q,
  [symbols.X]: symbol_x,
  [symbols.SC1]: symbol_sc1,
  [symbols.WL]: symbol_wl,
  [symbols.HP1]: symbol_hp1,
  [symbols.HP2]: symbol_hp2,
  [symbols.HP3]: symbol_hp3,
  [symbols.HP4]: symbol_hp4,
};

const config: IGameConfig = {
  REELS_COUNT: 5,
  SYMBOLS_IN_REEL: 3,
  SYMBOL_WIDTH: 96,
  SYMBOL_HEIGHT: 96,
  symbols,
  assets,
};

export default config;
