import type { PropsWithChildren } from 'react';
import type { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import type { IGameConfig, IPosition } from 'types';

interface IProps extends PropsWithChildren {
  config: IGameConfig;
  sx?: SxProps;
  position: IPosition;
}

export const SymbolOverlay = ({ config, sx, position, children }: IProps) => {
  return (
    <Box
      sx={{
        pointerEvents: 'none',
        display: 'flex',
        width: config.SYMBOL_WIDTH,
        height: config.SYMBOL_HEIGHT,
        position: 'absolute',
        left: position.x * config.SYMBOL_WIDTH,
        top: position.y * config.SYMBOL_HEIGHT,
        justifyContent: 'center',
        alignItems: 'center',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default SymbolOverlay;
