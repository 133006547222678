import type { IGameConfig } from 'types';
import symbol_a from './assets/symbol_a.png';
import symbol_b from './assets/symbol_b.png';
import symbol_c from './assets/symbol_c.png';
import symbol_d from './assets/symbol_d.png';
import symbol_e from './assets/symbol_e.png';
import symbol_f from './assets/symbol_f.png';
import symbol_g from './assets/symbol_g.png';
import symbol_h from './assets/symbol_h.png';
import symbol_i from './assets/symbol_i.png';
import symbol_j from './assets/symbol_j.png';
import symbol_k from './assets/symbol_k.png';
import symbol_sc from './assets/symbol_sc.png';
import symbol_wl from './assets/symbol_wl.png';

enum symbols {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  WL = 'WL',
  SC1 = 'SC1',
  SC2 = 'SC2',
  SC3 = 'SC3',
  SC4 = 'SC4',
  SC5 = 'SC5',
  SC6 = 'SC6',
  SC7 = 'SC7',
}

const assets: Record<symbols, string> = {
  [symbols.A]: symbol_a,
  [symbols.B]: symbol_b,
  [symbols.C]: symbol_c,
  [symbols.D]: symbol_d,
  [symbols.E]: symbol_e,
  [symbols.F]: symbol_f,
  [symbols.G]: symbol_g,
  [symbols.H]: symbol_h,
  [symbols.I]: symbol_i,
  [symbols.J]: symbol_j,
  [symbols.K]: symbol_k,
  [symbols.WL]: symbol_wl,
  [symbols.SC1]: symbol_sc,
  [symbols.SC2]: symbol_sc,
  [symbols.SC3]: symbol_sc,
  [symbols.SC4]: symbol_sc,
  [symbols.SC5]: symbol_sc,
  [symbols.SC6]: symbol_sc,
  [symbols.SC7]: symbol_sc,
};

const config: IGameConfig = {
  REELS_COUNT: 5,
  SYMBOLS_IN_REEL: 4,
  SYMBOL_WIDTH: 110,
  SYMBOL_HEIGHT: 83,
  symbols,
  assets,
};

export default config;
