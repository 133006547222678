import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Outcome, PaylinesList, SymbolOverlay } from 'components';
import type { IBet, IPayline } from 'types';
import { getFormattedMoney, getPositionFromIndex } from 'utils';
import GW from './assets/GW.png';
import SW from './assets/SW.png';
import config from './pachincaConfig';
import type { IPachincaFeatures } from './pachincaTypes';

interface IProps {
  data: IBet<IPachincaFeatures>;
}

const wheelImages: Record<string, string> = {
  GW,
  SW,
};

const parsePaylines = (data: IBet): IPayline[] => {
  const parsed: IPayline[] = [];
  data.paylines.forEach((payline) => {
    if (payline.winPositions.length === 0) {
      return;
    }
    payline.rewards.forEach((reward) => {
      parsed.push({
        ...payline,
        rewards: [reward],
      });
    });
  });

  return parsed;
};

export const Pachinca = ({ data }: IProps) => {
  const { t } = useTranslation();
  const outcome = data.features.base?.map((el) => [el]);

  const [currentPayline, setCurrentPayline] = useState<IPayline | null>(null);
  useEffect(() => setCurrentPayline(null), [data]);
  const paylines = useMemo(() => parsePaylines(data), [data]);

  return (
    <>
      {outcome && (
        <Outcome outcome={outcome} config={config}>
          {currentPayline?.winPositions.map((positionIndex) => (
            <SymbolOverlay
              key={positionIndex}
              position={getPositionFromIndex(positionIndex, config.REELS_COUNT)}
              config={config}
              sx={{ backgroundColor: 'primary.main', zIndex: -1 }}
            />
          ))}
        </Outcome>
      )}
      <PaylinesList
        paylines={paylines}
        coinValue={data.coinValue}
        coinAmount={data.coinAmount}
        currency={data.currency}
        currentPayline={currentPayline}
        setCurrentPayline={setCurrentPayline}
      />

      {data.features.wheel && (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <img
            style={{ width: config.SYMBOL_WIDTH * 0.75, height: config.SYMBOL_HEIGHT * 0.75 }}
            src={wheelImages[data.features.wheel.wheel]}
          />
          <ArrowForwardIcon fontSize="large" />
          <Typography variant="h4">x{data.features.wheel.reward}</Typography>
          <ArrowForwardIcon fontSize="large" />
          <Typography variant="h4">
            {getFormattedMoney(
              data.coinAmount * data.coinValue * data.coinAmountMultiplier * data.features.wheel.reward,
              data.currency,
            )}
          </Typography>
        </Box>
      )}

      {data.features.pachiDrop?.map((pachiDrop, index) => {
        const numberedReward = Number(pachiDrop.reward);
        return (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1, width: 377 }}>
            <img
              style={{ width: config.SYMBOL_WIDTH * 0.75, height: config.SYMBOL_HEIGHT * 0.75 }}
              src={config.assets[pachiDrop.symbol]}
            />
            <ArrowForwardIcon fontSize="large" />
            {Number.isNaN(numberedReward) ? (
              <>
                <img
                  style={{ width: config.SYMBOL_WIDTH * 0.75, height: config.SYMBOL_HEIGHT * 0.75 }}
                  src={wheelImages[pachiDrop.reward]}
                />
                <ArrowForwardIcon fontSize="large" />
                <Typography variant="h4">{t('respin')}</Typography>
              </>
            ) : (
              <>
                <Typography variant="h4">x{numberedReward}</Typography>
                <ArrowForwardIcon fontSize="large" />
                <Typography variant="h4">
                  {getFormattedMoney(
                    data.coinAmount * data.coinValue * data.coinAmountMultiplier * numberedReward,
                    data.currency,
                  )}
                </Typography>
              </>
            )}
          </Box>
        );
      })}
    </>
  );
};

export default Pachinca;
