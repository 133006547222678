import { useEffect, useMemo, useState } from 'react';
import { Outcome, PaylinesList, SymbolOverlay } from 'components';
import type { IBet, IPayline } from 'types';
import { getOutcome, getPositionFromIndex } from 'utils';
import config from './maiTaiPunchConfig';
import type { IMaiTaiPunchFeatures } from './maiTaiPunchTypes';

interface IProps {
  data: IBet<IMaiTaiPunchFeatures>;
}

const parsePaylines = (data: IBet<IMaiTaiPunchFeatures>) => {
  return data.paylines.map((payline) => {
    return {
      ...payline,
      rewards: payline.rewards.map((reward) =>
        payline.payoffType === 'ANY' && reward.multiplier
          ? { ...reward, multiplier: reward.multiplier * data.coinAmountMultiplier }
          : reward,
      ),
    };
  });
};

export const MaiTaiPunch = ({ data }: IProps) => {
  const outcome = useMemo(
    () => getOutcome(config.SYMBOLS_IN_REEL, data.reelset, data.stopPositions),
    [data.reelset, data.stopPositions],
  );
  const [currentPayline, setCurrentPayline] = useState<IPayline | null>(null);
  useEffect(() => setCurrentPayline(null), [data]);

  const paylines = useMemo(() => parsePaylines(data), [data]);

  return (
    <>
      <Outcome outcome={outcome} config={config}>
        {currentPayline?.winPositions.map((positionIndex) => (
          <SymbolOverlay
            key={positionIndex}
            position={getPositionFromIndex(positionIndex, config.REELS_COUNT)}
            config={config}
            sx={{ backgroundColor: 'primary.main', opacity: 0.5 }}
          />
        ))}
      </Outcome>
      <PaylinesList
        paylines={paylines}
        coinValue={data.coinValue}
        coinAmount={data.coinAmount}
        currency={data.currency}
        currentPayline={currentPayline}
        setCurrentPayline={setCurrentPayline}
      />
    </>
  );
};

export default MaiTaiPunch;
