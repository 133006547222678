import type { FC, ForwardRefExoticComponent, RefAttributes } from 'react';
import type { IPaytableProps } from 'components/Paytable';
import type { IRoundTitleProps } from 'components/RoundTitle';
import type { ITitleProps } from 'components/Title';
import type { IBet, IRawBet, IRawData } from 'types';
import BookOfWaifu, { BookOfWaifuPaytable } from './BookOfWaifu';
import DazzlingBox, { DazzlingBoxPaytable } from './DazzlingBox';
import DualFusion, { dualFusionMiddleware, DualFusionPaytable } from './DualFusion';
import ForbiddenPyramid from './ForbiddenPyramid';
import GoldenPirate, { goldenPirateMiddleware } from './GoldenPirate';
import HammerThunder from './HammerThunder';
import MaiTaiPunch from './MaiTaiPunch';
import MeteorOfChaos, { meteorOfChaosParseBetMiddleware } from './MeteorOfChaos';
import NinjaStrike, { NinjaStrikePaytable } from './NinjaStrike';
import PachiCroon from './PachiCroon';
import { pachiCroonMiddleware } from './PachiCroon/pachiCroonMiddleware';
import Pachinca, { pachincaMiddleware } from './Pachinca';
import ParadiseDelight from './ParadiseDelight';
import SakuraFantasia, { SakuraFantasiaPaytable } from './SakuraFantasia';
import SamuraiRumble, { SamuraiRumblePaytable } from './SamuraiRumble';
import SharkRush from './SharkRush';
import SpinHeist from './SpinHeist';
import { spinHeistMiddleware } from './SpinHeist/spinHeistMiddleware';
import WildFour, { WildFourPaytable } from './WildFour';
import WolfAndSheep from './WolfAndSheep';

interface IGame<T = never> {
  Component: FC<{ data: IBet }>;
  Paytable?: FC<IPaytableProps>;
  Title?: FC<ITitleProps>;
  RoundTitle?: ForwardRefExoticComponent<IRoundTitleProps & RefAttributes<HTMLDivElement>>;
  name: string;
  stickyTitle?: boolean;
  parseBetMiddleware?: (bet: IBet<T>, rawBet: IRawBet<T>, rawData: IRawData<T>) => IBet;
}

const games: Record<string, IGame> = {
  '41a1ac68-f793-4b0b-b833-0c68e5c431c7': {
    name: 'The Wild Four',
    Component: WildFour,
    Paytable: WildFourPaytable,
  },
  'ae830e1b-268f-4154-aabd-46732a66d99b': {
    name: 'Lucky Dual Fusion',
    Component: DualFusion,
    Paytable: DualFusionPaytable,
    parseBetMiddleware: dualFusionMiddleware,
    stickyTitle: true,
  },
  '77b701b7-5210-4cfa-9327-d6d3ab9f3317': {
    name: 'Golden Pirate',
    Component: GoldenPirate,
    parseBetMiddleware: goldenPirateMiddleware,
  },
  '5a6aacac-18c4-4a03-9457-98544dd385c6': {
    name: 'The Forbidden Pyramid',
    Component: ForbiddenPyramid,
  },
  'e98ecbf6-03ea-4ada-9e15-3da6d85fc257': {
    name: 'Mai Tai Punch',
    Component: MaiTaiPunch,
  },
  'ab23256b-992d-4bd0-8089-4aa1d560797b': {
    name: 'Sakura Fantasia',
    Component: SakuraFantasia,
    Paytable: SakuraFantasiaPaytable,
  },
  '18f3ab70-4155-401c-862d-7c5099e692f6': {
    name: 'Ninja Strike',
    Component: NinjaStrike,
    Paytable: NinjaStrikePaytable,
  },
  '9776a55d-ac49-401b-9a9e-691b4b2c7683': {
    name: 'Meteor of Chaos',
    Component: MeteorOfChaos,
    parseBetMiddleware: meteorOfChaosParseBetMiddleware,
    stickyTitle: true,
  },
  'db8f3359-8589-45cc-9409-d7b5c6801764': {
    name: 'Pachinca',
    Component: Pachinca,
    parseBetMiddleware: pachincaMiddleware,
  },
  '1560c13f-4707-494d-922b-b49dd1dfacf1': {
    name: 'Samurai Rumble',
    Component: SamuraiRumble,
    Paytable: SamuraiRumblePaytable,
  },
  'a7c653c1-d9cb-4e7b-982e-6edde5d38760': {
    name: 'Dazzling Box',
    Component: DazzlingBox,
    Paytable: DazzlingBoxPaytable,
  },
  '45069551-28bb-4af5-8965-2dea77488f3e': {
    name: 'Hammer & Thunder',
    Component: HammerThunder,
  },
  'eba7f94f-c50c-493d-bedb-476e41061b8a': {
    name: 'Paradise Delight',
    Component: ParadiseDelight,
  },
  '92d46cfe-6399-4132-9246-643d36ad4b7e': {
    name: 'Book of Waifu',
    Component: BookOfWaifu,
    Paytable: BookOfWaifuPaytable,
  },
  '977a152e-188e-49f8-b075-4c2c51f5fb30': {
    name: 'Shark Rush',
    Component: SharkRush,
  },
  'ef804251-8f17-443e-ac4e-7f42c9b1caae': {
    name: 'Spin Heist',
    Component: SpinHeist,
    parseBetMiddleware: spinHeistMiddleware,
  },
  'eb66909f-c5c5-4b39-aa20-311cf6952caf': {
    name: 'Pachi Croon',
    Component: PachiCroon,
    parseBetMiddleware: pachiCroonMiddleware,
  },
  '09bf2f76-f14f-43db-8630-6e17cc0dd6f2': {
    name: 'Wolf and Sheep',
    Component: WolfAndSheep,
  },
};

export default games;
