import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

interface IProps {
  scrollTo: (diff: number) => void;
}

const size = 42;

export const NavigationArrows = ({ scrollTo }: IProps) => {
  return (
    <Box sx={{ position: 'fixed', bottom: 20, right: 20, display: 'flex', flexDirection: 'column', gap: 1.5 }}>
      {[
        { icon: <KeyboardDoubleArrowUpIcon fontSize="large" />, scrollNumber: -Infinity },
        { icon: <KeyboardArrowUpIcon fontSize="large" />, scrollNumber: -1 },
        { icon: <KeyboardArrowDownIcon fontSize="large" />, scrollNumber: 1 },
        { icon: <KeyboardDoubleArrowDownIcon fontSize="large" />, scrollNumber: Infinity },
      ].map(({ icon, scrollNumber }) => (
        <Button
          key={scrollNumber}
          variant="contained"
          color="primary"
          sx={{
            width: size,
            height: size,
            minWidth: size,
            borderRadius: size,
            color: 'text.primary',
          }}
          onClick={() => scrollTo(scrollNumber)}
        >
          {icon}
        </Button>
      ))}
    </Box>
  );
};

export default NavigationArrows;
