import type { IGameConfig } from 'types';
import clownFish from './assets/clownFish.png';
import coral from './assets/coral.png';
import dolphin from './assets/dolphin.png';
import jellyFish from './assets/jellyFish.png';
import manta from './assets/manta.png';
import marlin from './assets/marlin.png';
import octopus from './assets/octopus.png';
import scatter from './assets/scatter.png';
import seal from './assets/seal.png';
import shark from './assets/shark.png';
import shell from './assets/shell.png';
import turtle from './assets/turtle.png';
import wild from './assets/wild.png';

enum symbols {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  WL = 'WL',
  SC1 = 'SC1',
  SC2 = 'SC2',
  SC3 = 'SC3',
  SC4 = 'SC4',
  SC5 = 'SC5',
  SC6 = 'SC6',
}

const assets: Record<symbols, string> = {
  [symbols.A]: shark,
  [symbols.B]: marlin,
  [symbols.C]: dolphin,
  [symbols.D]: seal,
  [symbols.E]: manta,
  [symbols.F]: turtle,
  [symbols.G]: octopus,
  [symbols.H]: clownFish,
  [symbols.I]: coral,
  [symbols.J]: jellyFish,
  [symbols.K]: shell,
  [symbols.WL]: wild,
  [symbols.SC1]: scatter,
  [symbols.SC2]: scatter,
  [symbols.SC3]: scatter,
  [symbols.SC4]: scatter,
  [symbols.SC5]: scatter,
  [symbols.SC6]: scatter,
};

const config: IGameConfig = {
  REELS_COUNT: 5,
  SYMBOLS_IN_REEL: 4,
  SYMBOL_WIDTH: 128,
  SYMBOL_HEIGHT: 102.4,
  symbols,
  assets,
};

export enum GameMode {
  REGULAR,
  FREE_SPINS_LVL1,
  FREE_SPINS_LVL2,
  FREE_SPINS_LVL3,
  FREE_SPINS_LVL4,
  FREE_SPINS_LVL5,
}

export const reelSetLevel = {
  'a11cadcd-28bf-4e6c-8eca-a7d9d7cb6217': GameMode.REGULAR,
  'ff4331fb-6b61-46e3-b5f4-01dd140c4061': GameMode.FREE_SPINS_LVL1,
  '78ebfe79-a9c2-4a11-b667-d358ba496aed': GameMode.FREE_SPINS_LVL2,
  '97efbc9f-a69b-416f-b458-040f6de63b21': GameMode.FREE_SPINS_LVL3,
  '0bfbb40c-6776-4b7a-81dd-1ab60ef43498': GameMode.FREE_SPINS_LVL4,
  '2d01a23a-fe97-49e2-8ebc-d3b159d97ae2': GameMode.FREE_SPINS_LVL5,
};

export default config;
