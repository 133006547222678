import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { Layout } from 'components';
import { initTranslations } from 'translations';
import GameHistory from './GameHistory';
import { useTheme } from './hooks';

initTranslations();

function App() {
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <Layout>
        <GameHistory />
      </Layout>
    </ThemeProvider>
  );
}

export default App;
