import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { TFunction } from 'i18next';
import type { IPayline } from 'types';
import { getFormattedMoney } from 'utils/getFormattedMoney';

interface IProps {
  paylines: IPayline[];
  currentPayline: IPayline | null;
  setCurrentPayline: (payline: IPayline | null) => void;
  coinValue: number;
  coinAmount: number;
  currency: string;
  multiplier?: number;
  getPaylineLabel?: (
    t: TFunction,
    payline: IPayline,
    coinValue: number,
    coinAmount: number,
    currency: string,
    multiplier?: number,
  ) => string;
  sx?: SxProps;
}

const defaultGetPaylineLabel = (
  t: TFunction,
  payline: IPayline,
  coinValue: number,
  coinAmount: number,
  currency: string,
  multiplier?: number,
) => {
  const reward = payline.rewards[0];
  if (!reward) {
    return t('noWinPayline');
  }
  if (reward.type === 'BONUS') {
    if (Number(reward.count) <= 1) {
      return t('winBonus');
    }
    return t('winFreeSpinsPayline', { count: reward.count });
  }
  return t('winMoneyPayline', {
    win: getFormattedMoney((reward.multiplier || 0) * coinValue * coinAmount * (multiplier || 1), currency),
  });
};

export const PaylinesList = ({
  paylines,
  coinValue,
  currentPayline,
  coinAmount,
  setCurrentPayline,
  multiplier,
  currency,
  getPaylineLabel = defaultGetPaylineLabel,
  sx,
}: IProps) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', maxWidth: 500, justifyContent: 'center', ...sx }}>
      {paylines.map((payline, paylineIndex) => (
        <Chip
          color="primary"
          key={paylineIndex}
          label={getPaylineLabel(t, payline, coinValue, coinAmount, currency, multiplier)}
          onClick={() => (currentPayline === payline ? setCurrentPayline(null) : setCurrentPayline(payline))}
          variant={currentPayline === payline ? 'filled' : 'outlined'}
        />
      ))}
    </Box>
  );
};

export default PaylinesList;
