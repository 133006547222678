import { initReactI18next } from 'react-i18next';
import { langAtom } from 'atoms';
import i18n from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { getDefaultStore } from 'jotai';

export const store = getDefaultStore();

store.sub(langAtom, () => {
  i18n.changeLanguage(store.get(langAtom));
});

export async function initTranslations(): Promise<void> {
  await i18n
    .use(
      resourcesToBackend((language: string) => {
        return import(`./resources.${language}.json`);
      }),
    )
    .use(initReactI18next)
    .init({
      debug: false,
      fallbackLng: 'en',
      lng: store.get(langAtom),
      load: 'languageOnly',
    });
}

export default i18n;
