import { SvgIcon } from '@mui/material';

export const PachiCroonBall = () => {
  return (
    <SvgIcon width="60" height="60" viewBox="0 0 95 95">
      <defs>
        <radialGradient id="ballGradient" cx="50%" cy="50%" r="50%">
          <stop offset="0%" stopColor="#f28383" stopOpacity="0.4" />
          <stop offset="30%" stopColor="#d43d3d" stopOpacity="0.8" />
          <stop offset="100%" stopColor="#f2cbcb" stopOpacity="1" />
        </radialGradient>
        <filter id="glow" x="-50%" y="-50%" width="200%" height="200%">
          <feGaussianBlur stdDeviation="3" result="coloredBlur" />
          <feMerge>
            <feMergeNode in="coloredBlur" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="smallBlur" x="-50%" y="-50%" width="200%" height="200%">
          <feGaussianBlur stdDeviation="2" />
        </filter>
      </defs>

      <circle cx="55" cy="40" r="40" fill="url(#ballGradient)" filter="url(#glow)" stroke="#575757" strokeWidth="3" />
      <circle cx="28" cy="27" r="12" fill="white" opacity="0.65" filter="url(#smallBlur)" />
    </SvgIcon>
  );
};
