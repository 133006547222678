import type { IGameConfig } from 'types';
import symbol_a_normal from './assets/symbol_a_normal.png';
import symbol_b_normal from './assets/symbol_b_normal.png';
import symbol_c_normal from './assets/symbol_c_normal.png';
import symbol_d_normal from './assets/symbol_d_normal.png';
import symbol_e_normal from './assets/symbol_e_normal.png';
import symbol_f_normal from './assets/symbol_f_normal.png';
import symbol_g_normal from './assets/symbol_g_normal.png';
import symbol_h_normal from './assets/symbol_h_normal.png';
import symbol_i_normal from './assets/symbol_i_normal.png';
import symbol_j_normal from './assets/symbol_j_normal.png';

enum symbols {
  WL = 'WL',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
}

const assets: Record<symbols, string> = {
  [symbols.WL]: symbol_a_normal,
  [symbols.B]: symbol_b_normal,
  [symbols.C]: symbol_c_normal,
  [symbols.D]: symbol_d_normal,
  [symbols.E]: symbol_e_normal,
  [symbols.F]: symbol_f_normal,
  [symbols.G]: symbol_g_normal,
  [symbols.H]: symbol_h_normal,
  [symbols.I]: symbol_i_normal,
  [symbols.J]: symbol_j_normal,
};

const config: IGameConfig = {
  REELS_COUNT: 5,
  SYMBOLS_IN_REEL: 3,
  SYMBOL_WIDTH: 128,
  SYMBOL_HEIGHT: 128,
  symbols,
  assets,
};

export default config;
