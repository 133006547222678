import games from 'games';
import type { IBet, IData, IRawData } from 'types';

export const parseRawData = (rawData: IRawData): IData => {
  const data: IData = {
    slotId: rawData.data.staticReplayBets.slot.id,
    icons: rawData.data.staticReplayBets.slot.icons,
    totalBet: 0,
    totalWin: 0,
    playerId: '',
    currency: '',
    bets: [],
  };

  const game = games[data.slotId];

  const checkedBonusTransactions: Record<string, boolean> = {};

  const getBonusTransaction = (betId: string, type: 'DEBIT' | 'CREDIT') => {
    const transaction = rawData.data.staticReplayBets.bonusTransactions.find(
      (el) => el.betId === betId && el.transaction.type === type,
    );

    if (!transaction) {
      return null;
    }

    if (checkedBonusTransactions[transaction?.transaction.id]) {
      return null;
    }

    checkedBonusTransactions[transaction?.transaction.id] = true;
    return transaction.transaction;
  };

  rawData.data.staticReplayBets.bets.forEach((rawBet) => {
    const reelset = rawData.data.staticReplayBets.slot.reels.find((reel) => reel.id === rawBet.reelSetId)?.layout;
    if (!reelset) {
      throw new Error(`Reelset with id ${rawBet.reelSetId} not found`);
    }

    const betResult = rawData.data.staticReplayBets.betResults[rawBet.id];
    if (!betResult) {
      throw new Error(`Bet result for bet ${rawBet.id} not found`);
    }

    const betTransaction = rawData.data.staticReplayBets.transactions.find(
      (transaction) => transaction.entityId === rawBet.id && transaction.type === 'DEBIT',
    );

    const winTransaction = rawData.data.staticReplayBets.transactions.find(
      (transaction) => transaction.entityId === rawBet.id && transaction.type === 'CREDIT',
    );
    if (!betTransaction || !winTransaction) {
      throw new Error(`Transaction for bet ${rawBet.id} not found`);
    }

    const bonusBetTransaction = getBonusTransaction(rawBet.id, 'DEBIT');
    const bonusWinTransaction = getBonusTransaction(rawBet.id, 'CREDIT');

    const bet: IBet = {
      bet: Number(betTransaction.amount) + (Number(bonusBetTransaction?.amount) || 0),
      win: Number(winTransaction.amount) + (Number(bonusWinTransaction?.amount) || 0),
      currency: betTransaction.currency,
      coinValue: rawBet.coinValue,
      coinAmount: rawBet.coinAmount,
      stopPositions: rawBet.result.reelPositions,
      reelsetId: rawBet.reelSetId,
      reelset,
      paylines: betResult.paylines,
      features: betResult.features,
      createdAt: rawBet.createdAt,
      id: rawBet.id,
      playerId: betTransaction.userId,
      coinAmountMultiplier: rawData.data.staticReplayBets.slot.lineSets[0]?.coinAmountMultiplier || 25,
      bonusId: rawBet.userBonus?.bonusId || null,
    };

    data.totalBet += bet.bet;
    data.totalWin += bet.win;
    data.playerId = bet.playerId;
    data.currency = bet.currency;

    data.bets.push(game?.parseBetMiddleware?.(bet as never, rawBet as never, rawData as never) || bet);
  });

  return data;
};
