import type { IGameConfig } from 'types';
import A from './assets/symbol_A.png';
import B from './assets/symbol_B.png';
import C from './assets/symbol_C.png';
import D from './assets/symbol_D.png';
import E from './assets/symbol_E.png';
import F from './assets/symbol_F.png';
import G from './assets/symbol_G.png';
import H from './assets/symbol_H.png';
import S_C from './assets/symbol_S_C.png';
import W_L from './assets/symbol_W_L.png';

enum symbols {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  WL = 'WL',
  SC = 'SC1',
}

const assets: Record<symbols, string> = {
  [symbols.A]: A,
  [symbols.B]: B,
  [symbols.C]: C,
  [symbols.D]: D,
  [symbols.E]: E,
  [symbols.F]: F,
  [symbols.G]: G,
  [symbols.H]: H,
  [symbols.SC]: S_C,
  [symbols.WL]: W_L,
};

const config: IGameConfig = {
  REELS_COUNT: 5,
  SYMBOLS_IN_REEL: 3,
  SYMBOL_WIDTH: 100,
  SYMBOL_HEIGHT: 100,
  symbols,
  assets,
};

export default config;
