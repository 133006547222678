import type { IGameConfig } from 'types';
import A from './assets/A.png';
import B from './assets/B.png';
import C from './assets/C.png';
import D from './assets/D.png';
import E from './assets/E.png';
import F from './assets/F.png';
import G from './assets/G.png';
import H from './assets/H.png';
import I from './assets/I.png';
import SC1 from './assets/S_C1.png';
import SC2 from './assets/S_C2.png';

enum symbols {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  SC1 = 'SC1',
  SC2 = 'SC2',
  SC3 = 'SC3',
  SC4 = 'SC4',
  SC5 = 'SC5',
  SC6 = 'SC6',
  SC7 = 'SC7',
  SC8 = 'SC8',
}

const assets: Record<symbols, string> = {
  [symbols.A]: A,
  [symbols.B]: B,
  [symbols.C]: C,
  [symbols.D]: D,
  [symbols.E]: E,
  [symbols.F]: F,
  [symbols.G]: G,
  [symbols.H]: H,
  [symbols.I]: I,
  [symbols.SC1]: SC1,
  [symbols.SC3]: SC1,
  [symbols.SC5]: SC1,
  [symbols.SC7]: SC1,
  [symbols.SC2]: SC2,
  [symbols.SC4]: SC2,
  [symbols.SC6]: SC2,
  [symbols.SC8]: SC2,
};

const config: IGameConfig = {
  REELS_COUNT: 6,
  SYMBOLS_IN_REEL: 6,
  SYMBOL_WIDTH: 64,
  SYMBOL_HEIGHT: 64,
  symbols,
  assets,
};

export default config;
