import { buildOutcomeFromMatrix } from 'utils/helpers';
import type { ISpinAndGrabFeature, ISpinAndGrabRound, ISpinAndGrabSlot } from './spinHeistTypes';

export const createSpinGrabRounds = (spinAndGrabFeature: ISpinAndGrabFeature) => {
  const position = spinAndGrabFeature.startingCells;
  const rounds = spinAndGrabFeature.rounds;

  const builtRounds = buildRounds([position], rounds);

  const symbolMatrixes = builtRounds.map((round) => round.map((spin) => spin.symbol));

  return {
    values: builtRounds.map((round) => round.filter((spin) => spin.value)),
    outcomes: symbolMatrixes.map((matrix) => buildOutcomeFromMatrix(matrix, 5)),
  };
};

const buildRounds = (builtRounds: ISpinAndGrabSlot[][], roundsToApply: ISpinAndGrabRound[]) => {
  if (roundsToApply.length === 0) {
    return builtRounds;
  }

  const newMatrix = getNewPosition(builtRounds[builtRounds.length - 1], roundsToApply[0]);

  return buildRounds([...builtRounds, newMatrix], roundsToApply.slice(1));
};

const getNewPosition = (position: ISpinAndGrabSlot[], { newCells }: ISpinAndGrabRound) => {
  return position.map((element) => {
    const cell = newCells.find((cell) => cell.position === element.position);
    return cell || element;
  });
};
