import { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import { Outcome, PaylinesList, SymbolOverlay } from 'components';
import { useParentBet } from 'hooks';
import type { IBet, IPayline, IPosition } from 'types';
import { getPositionFromIndex } from 'utils';
import NinjaA_off from './assets/NinjaA_off.jpg';
import NinjaA from './assets/NinjaA.jpg';
import NinjaB_off from './assets/NinjaB_off.jpg';
import NinjaB from './assets/NinjaB.jpg';
import NinjaC_off from './assets/NinjaC_off.jpg';
import NinjaC from './assets/NinjaC.jpg';
import NinjaAx3 from './assets/Symbol_A_Ninjax3.png';
import NinjaBx3 from './assets/Symbol_B_Ninjax3.png';
import NinjaCx3 from './assets/Symbol_C_Ninjax3.png';
import config from './ninjaStrikeConfig';
import NinjaStrikeGrant from './NinjaStrikeGrant';
import type { INinjaStrikeFeatures } from './ninjaStrikeTypes';

interface IProps {
  data: IBet<INinjaStrikeFeatures>;
}

const NINJAS_REELSETS: Record<string, string[]> = {
  'aba38ec0-7200-4521-aaaa-87e4ca379b2b': [config.symbols.C],
  '330867cd-320d-4713-bb87-a59b5f434bef': [config.symbols.C, config.symbols.B],
  '36d09545-745c-4c88-bad0-26951691fd04': [config.symbols.C, config.symbols.B, config.symbols.A],
};

const FREE_SPINS_REELSETS = [
  '2994df84-9fa1-4602-97ea-31d97ff92c1f',
  'aba38ec0-7200-4521-aaaa-87e4ca379b2b',
  '330867cd-320d-4713-bb87-a59b5f434bef',
  '36d09545-745c-4c88-bad0-26951691fd04',
];

const NINJAS = [
  { id: config.symbols.A, img: NinjaA, imgOff: NinjaA_off },
  { id: config.symbols.B, img: NinjaB, imgOff: NinjaB_off },
  { id: config.symbols.C, img: NinjaC, imgOff: NinjaC_off },
];

const COLOSSAL_SYMBOL_IMAGES: Record<string, string> = {
  [config.symbols.A]: NinjaAx3,
  [config.symbols.B]: NinjaBx3,
  [config.symbols.C]: NinjaCx3,
};

export const NinjaStrike = ({ data }: IProps) => {
  const activatedNinjas = NINJAS_REELSETS[data.reelsetId] || [];
  const showNinjas = FREE_SPINS_REELSETS.includes(data.reelsetId);

  const { outcome, colossals } = useMemo(() => {
    const outcome: string[][] = [];
    const colossals: Record<
      string,
      {
        position: IPosition;
        img: string;
      }
    > = {};

    data.reelset.forEach((reel, reelIndex) => {
      if (reel.length === 0) {
        return;
      }
      const reelOutcome: string[] = [];
      let symbolIndex = data.stopPositions[reelIndex]! - 1;
      if (symbolIndex < 0) {
        symbolIndex = reel.length - 1;
      }

      const getNeighborSymbol = (index: number, offset: number) => {
        const raw = reel[index + offset];
        if (raw) {
          return raw[0];
        }
        if (offset < 0) {
          return reel[reel.length + offset + index]?.[0] || ' ';
        }
        return reel[offset - 1 + index]?.[0] || ' ';
      };

      while (reelOutcome.length < config.SYMBOLS_IN_REEL) {
        if (!reel[symbolIndex]) {
          symbolIndex = 0;
        }
        const symbol = reel[symbolIndex];
        if (activatedNinjas.includes(symbol)) {
          const collosalString = symbol + symbol + symbol;
          const reelPart = [
            getNeighborSymbol(symbolIndex, -2),
            getNeighborSymbol(symbolIndex, -1),
            symbol,
            getNeighborSymbol(symbolIndex, 1),
            getNeighborSymbol(symbolIndex, 2),
          ].join('');

          if (reelPart.includes(collosalString)) {
            const position = { x: reelIndex, y: reelOutcome.length - 2 + reelPart.indexOf(collosalString) };
            colossals[position.x + 'x' + position.y] = {
              position,
              img: COLOSSAL_SYMBOL_IMAGES[symbol],
            };
          }
        }
        reelOutcome.push(symbol!);
        symbolIndex += 1;
      }
      outcome.push(reelOutcome);
    });

    return { outcome, colossals: Object.values(colossals) };
  }, [data.reelset, data.stopPositions]);

  const [currentPayline, setCurrentPayline] = useState<IPayline | null>(null);
  useEffect(() => setCurrentPayline(null), [data]);
  const paylines = useMemo(() => {
    const paylines: IPayline[] = [];

    data.paylines.forEach((payline) => {
      payline.rewards.forEach((reward) => {
        const parsedReward = { ...reward };
        // scatter gives 5 FS, there can be more because of shurkien strike
        if (parsedReward.type === 'BONUS') {
          parsedReward.count = 5;
        }
        if (payline.rewards.length > 1 && parsedReward.multiplier) {
          parsedReward.multiplier *= data.coinAmountMultiplier;
        }
        paylines.push({
          ...payline,
          rewards: [parsedReward],
        });
      });
    });

    return paylines;
  }, [data]);

  const { parentBetFeatures, betIndex } = useParentBet(data);
  const shurkienData = parentBetFeatures?.bonusData?.preLoadedGrantBreakdown?.[betIndex];
  const grant = shurkienData?.grants[0]?.grants[0];

  if (grant) {
    return <NinjaStrikeGrant data={data} grant={grant} />;
  }

  return (
    <>
      {showNinjas && <Box sx={{ height: 64 }} />}
      <Outcome outcome={outcome} config={config}>
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            overflow: 'hidden',
            pointerEvents: 'none',
          }}
        >
          {colossals.map(({ position, img }) => (
            <Box
              key={position.x + 'x' + position.y}
              sx={{
                position: 'absolute',
                left: config.SYMBOL_WIDTH * position.x,
                top: config.SYMBOL_HEIGHT * position.y,
                width: config.SYMBOL_WIDTH,
                height: config.SYMBOL_HEIGHT * 3,
              }}
            >
              <img src={img} style={{ width: '100%', height: '100%' }} />
            </Box>
          ))}
        </Box>
        {currentPayline?.winPositions.map((positionIndex) => (
          <SymbolOverlay
            key={positionIndex}
            position={getPositionFromIndex(positionIndex, config.REELS_COUNT)}
            config={config}
            sx={{ backgroundColor: 'primary.main', opacity: 0.6 }}
          />
        ))}
        {showNinjas && (
          <Box sx={{ display: 'flex', position: 'absolute', top: -68 }}>
            {NINJAS.map((ninja) => (
              <img
                style={{ width: 64, height: 64 }}
                key={ninja.id}
                src={activatedNinjas.includes(ninja.id) ? ninja.img : ninja.imgOff}
              />
            ))}
          </Box>
        )}
      </Outcome>
      <PaylinesList
        paylines={paylines}
        coinValue={data.coinValue}
        coinAmount={data.coinAmount}
        currency={data.currency}
        currentPayline={currentPayline}
        setCurrentPayline={setCurrentPayline}
      />
    </>
  );
};

export default NinjaStrike;
