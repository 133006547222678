import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IBet } from 'types';
import { getFormattedMoney } from 'utils';
import { IHammerThunderFeatures } from './hammerThunderTypes';

interface IProps {
  data: IBet<IHammerThunderFeatures>;
  multiplier: number;
  img: string;
}

export const HammerThunderBonus = ({ data, multiplier, img }: IProps) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <img src={img} style={{ width: 128, height: 128 }} />
      <ArrowForwardIcon fontSize="large" />
      <Typography variant="h4">x{multiplier}</Typography>
      <ArrowForwardIcon fontSize="large" />
      <Typography variant="h4">
        {getFormattedMoney(multiplier * data.coinAmount * data.coinValue * data.coinAmountMultiplier, data.currency)}
      </Typography>
    </Box>
  );
};

export default HammerThunderBonus;
