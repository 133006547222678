export function buildOutcomeFromMatrix(matrix: string[], width: number) {
  const length = matrix.length;
  const outcomeElementLength = length / width;
  const outcome = [];
  let i = 0;

  while (i < width) {
    const newArr: string[] = [];
    outcome[i] = newArr;
    for (let j = 0; j < outcomeElementLength; j++) {
      outcome[i].push(matrix[i + j * width]);
    }
    ++i;
  }

  return outcome;
}
