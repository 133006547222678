import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import CenterBox from './CenterBox';

interface IProps {
  error: string;
  onButtonClick: () => void;
}

export const Error = ({ error, onButtonClick }: IProps) => {
  const { t } = useTranslation();
  return (
    <CenterBox>
      <Alert variant="filled" severity="error" sx={{ maxWidth: 500 }}>
        <AlertTitle>{t('errorOccured')}</AlertTitle>
        {error}
      </Alert>
      <Button fullWidth sx={{ mt: 2 }} size="large" variant="contained" onClick={onButtonClick}>
        {t('tryAgain')}
      </Button>
    </CenterBox>
  );
};

export default Error;
