import { ComponentProps } from 'react';
import { Box } from '@mui/material';
import { IBet } from 'types';
import dolphin from './assets/dolphin.png';
import manta from './assets/manta.png';
import marlin from './assets/marlin.png';
import seal from './assets/seal.png';
import shark from './assets/shark.png';
import { reelSetLevel } from './sharkRushConfig';
import { ISharkRushFeatures } from './sharkRushTypes';

interface IProps {
  data: IBet<ISharkRushFeatures>;
}

const offset = 80;

export const SharkRushLevelComponent = ({ data }: IProps) => {
  return (
    data.reelsetId !== 'a11cadcd-28bf-4e6c-8eca-a7d9d7cb6217' && (
      <Box sx={{ minHeight: 80 }}>
        <Box sx={{ display: 'flex' }}>
          <Image src={manta} sx={{ position: 'absolute', left: offset * 0 }} />
          <Image src={seal} sx={{ position: 'absolute', left: offset * 1 }} />
          <Image src={dolphin} sx={{ position: 'absolute', left: offset * 2 }} />
          <Image src={marlin} sx={{ position: 'absolute', left: offset * 3 }} />

          {Array.from({ length: reelSetLevel[data.reelsetId as keyof typeof reelSetLevel] - 1 }).map((_, i) => {
            return <Image key={i} src={shark} sx={{ position: 'absolute', left: offset * i }} />;
          })}
        </Box>
      </Box>
    )
  );
};

const Image = ({ src, ...props }: ComponentProps<typeof Box> & { src: string }) => {
  return (
    <Box {...props}>
      <img src={src} width={100} height={80} />
    </Box>
  );
};
