import { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import { paytableAtom } from 'atoms';
import { Outcome, PaylinesList, SymbolOverlay } from 'components';
import { useSetAtom } from 'jotai';
import type { IBet, IPayline } from 'types';
import { getOutcome, getPositionFromIndex, parse2RewardsPaylines } from 'utils';
import config from './sakuraFantasiaConfig';
import type { ISakuraFantasiaFeatures } from './sakuraFantasiaTypes';

export const reelSetsLevels: Record<string, number> = {
  '949dd59b-4b6d-4099-a00a-ae2c2861f951': 0,
  '7a5565b4-ff66-4049-bb40-f9e3c07e85d0': 1,
  'fcf31324-4835-4340-b41a-84609c9012ed': 2,
  '1839621e-c089-446f-aeef-36c6b4e684c2': 3,
  'a6082cbf-b675-4a0e-99e6-52c509b00173': 4,
  '1377385c-02a1-46ea-9a31-e9018d960541': 5,
};

interface IProps {
  data: IBet<ISakuraFantasiaFeatures>;
}

const LEVEL_SYBMOLS = [config.symbols.E, config.symbols.D, config.symbols.C, config.symbols.B];
const CURRENT_LEVEL_SYMBOL = config.symbols.A;

export const SakuraFantasia = ({ data }: IProps) => {
  const setPaytable = useSetAtom(paytableAtom);

  const outcome = useMemo(
    () => getOutcome(config.SYMBOLS_IN_REEL, data.reelset, data.stopPositions),
    [data.reelset, data.stopPositions],
  );
  const [currentPayline, setCurrentPayline] = useState<IPayline | null>(null);
  useEffect(() => setCurrentPayline(null), [data]);
  const paylines = useMemo(() => parse2RewardsPaylines(data), [data]);

  const currentLevel = reelSetsLevels[data.reelsetId] || 0;
  const levelSymbols = LEVEL_SYBMOLS.map((el, index) => (currentLevel > index + 1 ? CURRENT_LEVEL_SYMBOL : el));

  return (
    <>
      <Outcome outcome={outcome} config={config}>
        {currentPayline?.winPositions.map((positionIndex) => (
          <SymbolOverlay
            key={positionIndex}
            position={getPositionFromIndex(positionIndex, config.REELS_COUNT)}
            config={config}
            sx={{ backgroundColor: 'primary.main', opacity: 0.5 }}
          />
        ))}
      </Outcome>
      {currentLevel > 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          {levelSymbols.map((symbol, index) => (
            <Box
              key={index}
              sx={{
                position: 'relative',
                width: config.SYMBOL_WIDTH * 0.7,
                height: config.SYMBOL_HEIGHT * 0.7,
                cursor: 'pointer',
              }}
              onClick={() =>
                setPaytable((currentPaytable) =>
                  symbol === currentPaytable?.symbol ? null : { symbol, gameConfig: config },
                )
              }
            >
              <img style={{ width: '100%', height: '100%' }} src={config.assets[symbol]} />
            </Box>
          ))}
        </Box>
      )}
      <PaylinesList
        paylines={paylines}
        coinValue={data.coinValue}
        coinAmount={data.coinAmount}
        currency={data.currency}
        currentPayline={currentPayline}
        setCurrentPayline={setCurrentPayline}
      />
    </>
  );
};

export default SakuraFantasia;
