import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { Outcome, PaylinesList, SymbolOverlay } from 'components';
import { TFunction } from 'i18next';
import type { IBet, IPayline } from 'types';
import { getFormattedMoney, getPositionFromIndex, parse2RewardsPaylines } from 'utils';
import config from './wolfAndSheepConfig';
import type { IWolfAndSheepFeatures } from './wolfAndSheepTypes';

interface IProps {
  data: IBet<IWolfAndSheepFeatures>;
}

const getOutcome = (matrix: string[], reels: number = 5, symbols: number = 3) => {
  const outcome: string[][] = Array.from({ length: reels }, () => []);

  for (let reel = 0; reel < reels; reel++) {
    for (let symbol = 0; symbol < symbols; symbol++) {
      const index = symbol * reels + reel;
      outcome[reel].push(matrix[index]);
    }
  }

  return outcome;
};

const getWoolState = (bet: IProps['data']) => {
  const { coinAmount } = bet;
  return bet.features.gameRoundStore.wolfShavesSheep.woolBag[coinAmount];
};

const parseRewards = (data: IProps['data']) => {
  return parse2RewardsPaylines(data).map((payline) => {
    return payline.type === 'PRE_DETERMINED'
      ? {
          ...payline,
          rewards: payline.rewards.map((reward) => ({
            ...reward,
            multiplier: data.coinAmountMultiplier,
          })),
          payoffType: 'feature',
        }
      : payline;
  });
};

const getPaylineLabel = (
  t: TFunction,
  payline: IPayline,
  coinValue: number,
  coinAmount: number,
  currency: string,
  multiplier?: number,
) => {
  const reward = payline.rewards[0];

  if (!reward) {
    return t('noWinPayline');
  }
  if (payline.payoffType === 'feature') {
    return t('winFeaturePayline', {
      win: getFormattedMoney((reward.multiplier || 0) * coinValue * coinAmount * (multiplier || 1), currency),
    });
  }
  if (reward.type === 'BONUS') {
    if (Number(reward.count) <= 1) {
      return t('winBonus');
    }
    return t('winFreeSpinsPayline', { count: reward.count });
  }
  return t('winMoneyPayline', {
    win: getFormattedMoney((reward.multiplier || 0) * coinValue * coinAmount * (multiplier || 1), currency),
  });
};

export const WolfAndSheep = ({ data }: IProps) => {
  const outcome = useMemo(
    () => getOutcome(data.features.gameRoundStore.spinMatrix),
    [data.reelset, data.stopPositions],
  );
  const [currentPayline, setCurrentPayline] = useState<IPayline | null>(null);
  useEffect(() => setCurrentPayline(null), [data]);
  const paylines = useMemo(() => parseRewards(data), [data]);

  const wool = getWoolState(data);

  const { t } = useTranslation();

  return (
    <>
      <Outcome outcome={outcome} config={config}>
        {currentPayline?.winPositions.map((positionIndex) => (
          <SymbolOverlay
            key={positionIndex}
            position={getPositionFromIndex(positionIndex, config.REELS_COUNT)}
            config={config}
            sx={{ backgroundColor: 'primary.main', opacity: 0.5 }}
          />
        ))}
      </Outcome>

      <Box>
        {data.features.gameRoundStore.assignFreeSpinsOutputData.freeSpins.played > 0 ? (
          <Typography>
            {t('featureFreeSpinCount')}: {wool.bonus.collected} / {wool.bonus.total}
          </Typography>
        ) : (
          <Typography>
            {t('featureCount')}: {wool.base.collected} / {wool.base.total}
          </Typography>
        )}
      </Box>

      <PaylinesList
        paylines={paylines}
        coinValue={data.coinValue}
        coinAmount={data.coinAmount}
        currency={data.currency}
        currentPayline={currentPayline}
        setCurrentPayline={setCurrentPayline}
        getPaylineLabel={getPaylineLabel}
      />
    </>
  );
};

export default WolfAndSheep;
