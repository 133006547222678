import { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Outcome, PaylinesList, SymbolOverlay } from 'components';
import type { IBet, IPayline } from 'types';
import { getOutcome, getPositionFromIndex } from 'utils';
import fsFrame from './assets/freespin_frame.png';
import heartIcon from './assets/icon_heart.png';
import config from './bookOfWaifuConfig';
import type { IBookOfWaifuFeatures } from './bookOfWaifuTypes';

interface IProps {
  data: IBet<IBookOfWaifuFeatures>;
}

const NOT_FS_REELSETS = ['01c11571-4c38-4c7a-b7c8-967e4d137818', '7fa1a6c8-70f5-4afb-a798-61e8ee2ba026'];

const parsePaylines = (data: IBet) => {
  const parsed: IPayline[] = [];
  data.paylines.forEach((payline) => {
    payline.rewards.forEach((reward) => {
      const parsedReward = { ...reward };
      if (payline.type === 'PRE_DETERMINED' && parsedReward.multiplier) {
        parsedReward.multiplier *= data.coinAmountMultiplier;
      }
      if (payline.winPositions.length === 0) {
        const paylineWithPositions = data.paylines.find(
          (el) => el.type === 'PRE_DETERMINED' && el.winPositions.length > 0,
        );
        payline.winPositions = paylineWithPositions?.winPositions || [];
      }
      parsed.push({
        ...payline,
        rewards: [parsedReward],
      });
    });
  });
  return parsed;
};

export const BookOfWaifu = ({ data }: IProps) => {
  const outcome = useMemo(
    () => getOutcome(config.SYMBOLS_IN_REEL, data.reelset, data.stopPositions, 0),
    [data.reelset, data.stopPositions],
  );
  const [currentPayline, setCurrentPayline] = useState<IPayline | null>(null);
  useEffect(() => setCurrentPayline(null), [data]);
  const paylines = useMemo(() => parsePaylines(data), [data]);
  const isFreeSpins = useMemo(() => !NOT_FS_REELSETS.includes(data.reelsetId), [data.reelsetId]);
  const { gameRoundStore } = data.features;
  const hearts = useMemo(
    () => Array.from({ length: gameRoundStore?.meter || 0 }, (_, i) => ({ key: i, src: heartIcon })),
    [data],
  );

  return (
    <>
      {isFreeSpins && gameRoundStore && (
        <Box sx={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              transform: 'translateY(-10px)',
            }}
          >
            <Typography variant="h4">x{gameRoundStore.multiplier}</Typography>
            <Box sx={{ display: 'flex', gap: 1 }}>
              {hearts.map(({ key, src }, i) => (
                <img
                  key={key}
                  src={src}
                  style={{
                    width: 30,
                    opacity: (gameRoundStore.heart || gameRoundStore.spHeart * gameRoundStore.meter) > i ? 1 : 0.2,
                  }}
                />
              ))}
            </Box>
          </Box>
          <Box
            sx={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', height: 50 }}
          >
            <img src={fsFrame} style={{ width: 350 }} />
            <img
              style={{ width: 75, height: 75, left: 20, top: -16, position: 'absolute' }}
              src={config.assets[gameRoundStore.id]}
            />
            <Typography variant="h4" sx={{ transform: 'translateY(-40px)' }}>
              {gameRoundStore.playedFS}/{gameRoundStore.countFS}
            </Typography>
          </Box>
        </Box>
      )}
      <Outcome outcome={outcome} config={config}>
        {currentPayline?.winPositions.map((positionIndex) => (
          <SymbolOverlay
            key={positionIndex}
            position={getPositionFromIndex(positionIndex, config.REELS_COUNT)}
            config={config}
            sx={{ backgroundColor: 'primary.main', opacity: 0.5 }}
          />
        ))}
      </Outcome>
      <PaylinesList
        paylines={paylines}
        coinValue={data.coinValue}
        coinAmount={data.coinAmount}
        currency={data.currency}
        currentPayline={currentPayline}
        setCurrentPayline={setCurrentPayline}
      />
    </>
  );
};

export default BookOfWaifu;
