import type { IBet, IPayline } from 'types';

export const parse2RewardsPaylines = (data: IBet): IPayline[] => {
  const parsed: IPayline[] = [];

  data.paylines.forEach((payline) => {
    payline.rewards.forEach((reward) => {
      const parsedReward = { ...reward };
      if (payline.rewards.length > 1 && parsedReward.multiplier) {
        parsedReward.multiplier *= data.coinAmountMultiplier;
      }
      parsed.push({
        ...payline,
        rewards: [parsedReward],
      });
    });
  });

  return parsed;
};
