import { useTranslation } from 'react-i18next';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import { ShadowTypography } from 'components';
import { TFunction } from 'i18next';
import { IBet } from 'types';
import { getFormattedMoney } from 'utils';
import chestImg from './assets/chest.png';
import coinImg from './assets/coin.png';
import NinjaA from './assets/NinjaA.jpg';
import NinjaB from './assets/NinjaB.jpg';
import NinjaC from './assets/NinjaC.jpg';
import shurkienImg from './assets/shuriken.png';
import type { IGrant, INinjaStrikeFeatures } from './ninjaStrikeTypes';

interface IProps {
  data: IBet<INinjaStrikeFeatures>;
  grant: IGrant;
}

const NINJAS_IMAGES: Record<string, string> = {
  'c00ff515-1cc2-478b-b9ed-a9133e919c24': NinjaC,
  'bc0ff515-1798-42d4-8ec6-757f87aa7ade': NinjaB,
  'abcff515-91e7-43b1-a2b7-15680421cc45': NinjaA,
};

const getGrantContent = (t: TFunction, grant: IGrant, data: IBet<INinjaStrikeFeatures>) => {
  const ninjaImg = NINJAS_IMAGES[grant.id];

  if (ninjaImg && grant.count === 0) {
    return {
      img: ninjaImg,
      text: '',
    };
  }

  if (grant.type === 'BONUS' && Number(grant.count) > 0) {
    return {
      img: chestImg,
      text: t('addFreeSpinsNewLine', { count: grant.count }),
    };
  }

  return {
    img: coinImg,
    text: getFormattedMoney(
      (grant.multiplier || 0) * data.coinValue * data.coinAmount * data.coinAmountMultiplier,
      data.currency,
    ),
  };
};

export const NinjaStrikeGrant = ({ data, grant }: IProps) => {
  const { t } = useTranslation();
  const { img, text } = getGrantContent(t, grant, data);
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <img src={shurkienImg} alt="shurkien" />
      <ArrowForwardIcon fontSize="large" />
      <Box
        sx={{
          position: 'relative',
          minWidth: 180,
          minHeight: 128,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img style={{ position: 'absolute', width: 128, height: 128, zIndex: -1 }} src={img} />
        <ShadowTypography variant={'h4'} textAlign="center" sx={{ whiteSpace: 'pre-line' }}>
          {text}
        </ShadowTypography>
      </Box>
    </Box>
  );
};

export default NinjaStrikeGrant;
