import { useMemo } from 'react';
import { rawDataAtom } from 'atoms';
import { useAtomValue } from 'jotai';
import { IBet } from 'types';

export function useParentBet<
  Features extends {
    bonusData?: {
      betId?: string;
    };
  },
>(bet: IBet<Features>, offset = -1) {
  const rawData = useAtomValue(rawDataAtom);

  return useMemo(() => {
    const parentBetId = bet.features.bonusData?.betId || '';
    const parentBetFeatures: Features | void = rawData?.data.staticReplayBets.betResults[parentBetId]
      ?.features as Features;
    const relatedBets = rawData?.data.staticReplayBets.relatedBets.find((el) => el.originalBetId === parentBetId) || {
      related: [],
    };
    const betIndex = relatedBets.related.findIndex((el) => el.id === bet.id) + offset;
    return {
      parentBetFeatures,
      betIndex,
      relatedBets,
    };
  }, [bet, rawData]);
}
