import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Outcome, PaylinesList, SymbolOverlay } from 'components';
import { useParentBet } from 'hooks';
import type { IBet, IPayline } from 'types';
import { getOutcome, getPositionFromIndex } from 'utils';
import bottle1Img from './assets/bottle_1.png';
import bottle2Img from './assets/bottle_2.png';
import bottle3Img from './assets/bottle_3.png';
import bottle4Img from './assets/bottle_4.png';
import config from './wildFourConfig';
import type { IWildFourFeatures } from './wildFourTypes';

const bottles = [bottle1Img, bottle3Img, bottle2Img, bottle4Img, bottle2Img, bottle3Img, bottle4Img, bottle1Img];

interface IProps {
  data: IBet<IWildFourFeatures>;
}

const parsePaylines = (data: IBet<IWildFourFeatures>, multipliers: Record<number, number>) => {
  const paylines: IPayline[] = [];

  data.paylines.forEach((payline) => {
    payline.rewards.forEach((reward) => {
      if (reward.type === 'MULTIPLIER') {
        return;
      }
      if (reward.multiplier) {
        let multiplier = 0;
        payline.winPositions.forEach((positionIndex) => {
          multiplier += multipliers[positionIndex] || 0;
        });
        paylines.push({
          ...payline,
          rewards: [{ ...reward, multiplier: reward.multiplier * (multiplier || 1) }],
        });
      }
      if (reward.type === 'BONUS') {
        paylines.push({
          ...payline,
          rewards: [reward],
        });
      }
    });
  });

  return paylines;
};

export const WildFour = ({ data }: IProps) => {
  const { t } = useTranslation();
  const outcome = useMemo(() => {
    const outcome = getOutcome(config.SYMBOLS_IN_REEL, data.reelset, data.stopPositions);
    Object.keys(data.features.multipliers).forEach((key) => {
      const position = getPositionFromIndex(Number(key), config.REELS_COUNT);
      outcome[position.x][position.y] = config.symbols.WL;
    });
    return outcome;
  }, [data.reelset, data.stopPositions]);
  const [currentPayline, setCurrentPayline] = useState<IPayline | null>(null);
  useEffect(() => setCurrentPayline(null), [data]);
  const paylines = useMemo(() => parsePaylines(data, data.features.multipliers), [data]);
  const { parentBetFeatures } = useParentBet(data);

  if (parentBetFeatures?.bonusData?.preLoadedGrantBreakdown) {
    return (
      <>
        {parentBetFeatures.bonusData.preLoadedGrantBreakdown.map((grant, index) => (
          <Box key={grant.triggerId} sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <img src={bottles[index] || bottles[0]} style={{ width: 44, height: 81 }} />
            <ArrowForwardIcon fontSize="large" />
            <Typography variant="h5">{t('addFreeSpins', { count: grant.grants[0]?.grants[0]?.count || 1 })}</Typography>
          </Box>
        ))}
        <Box>
          <Typography variant="h4" sx={{ borderTop: '2px solid', borderColor: 'text.primary', mt: 1, pt: 1 }}>
            {t('totalFreeSpins', {
              count: parentBetFeatures.bonusData.preLoadedGrantBreakdown.reduce(
                (acc, grant) => acc + grant.grants[0]?.grants[0]?.count || 0,
                0,
              ),
            })}
          </Typography>
        </Box>
      </>
    );
  }

  return (
    <>
      {outcome.length > 0 && (
        <Outcome outcome={outcome} config={config}>
          {currentPayline?.winPositions.map((positionIndex) => (
            <SymbolOverlay
              key={positionIndex}
              position={getPositionFromIndex(positionIndex, config.REELS_COUNT)}
              config={config}
              sx={{ backgroundColor: 'primary.main', opacity: 0.5 }}
            />
          ))}
          {Object.entries(data.features.multipliers).map(([key, value]) => (
            <SymbolOverlay
              key={key}
              config={config}
              position={getPositionFromIndex(Number(key), config.REELS_COUNT)}
              sx={{ color: 'red', fontSize: 32, WebkitTextStroke: '1px black', marginTop: -1 }}
            >
              x{value}
            </SymbolOverlay>
          ))}
        </Outcome>
      )}
      <PaylinesList
        paylines={paylines}
        coinValue={data.coinValue}
        coinAmount={data.coinAmount}
        currency={data.currency}
        currentPayline={currentPayline}
        setCurrentPayline={setCurrentPayline}
      />
    </>
  );
};

export default WildFour;
