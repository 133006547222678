import { ArrowForward } from '@mui/icons-material';
import { Box, SxProps, Typography } from '@mui/material';
import Circus from './assets/cicus.png';
import { PachiCroonBall } from './PachiCroonBall';
import { IParsedRound, TPachiResult } from './pachiCroonTypes';

const outcomeStyles: SxProps = {
  position: 'absolute',
  zIndex: 1,
  color: '#4747cb',
  fontWeight: 1000,
};

const translatePachiResult = (result: TPachiResult): string => {
  const firstNumberRegexp = new RegExp(/\d+/);
  const match = firstNumberRegexp.exec(result);

  return match ? `x${match[0]}` : result;
};

const getBallCoordinates = (position: number): SxProps => {
  switch (position) {
    case 0:
      return {
        left: 136.5,
        top: 164,
        height: 15,
        width: 27.5,
      };
    case 1:
      return {
        left: 114.5,
        top: 141,
        height: 12,
        width: 25,
      };
    case 2:
      return {
        left: 159.5,
        top: 141,
        height: 12,
        width: 25,
      };
  }
  throw new Error('Wrong position supplied');
};

const getTextPosition = (result: TPachiResult, position: number) => {
  if (position === 2) {
    return {
      right: result === 'COLLECT' ? 65 : 100,
      bottom: 68,
    };
  } else if (position === 1) {
    return {
      left: result === 'COLLECT' ? 65 : 100,
      bottom: 68,
    };
  } else {
    return { left: 170, bottom: 24.5 };
  }
};

export const PachiCroonStage = ({ round }: { round: IParsedRound }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ width: 300, overflow: 'hidden', position: 'relative', margin: '0 auto' }}>
        <img src={Circus} style={{ objectFit: 'cover', width: '100%', height: '100%', transform: 'scale(1.5)' }} />

        <Typography sx={{ ...outcomeStyles, ...getTextPosition(round.stage[2], 2) }}>
          {translatePachiResult(round.stage[2])}
        </Typography>

        <Typography sx={{ ...outcomeStyles, ...getTextPosition(round.stage[1], 1) }}>
          {translatePachiResult(round.stage[1])}
        </Typography>

        <Typography sx={{ ...outcomeStyles, color: 'red', ...getTextPosition(round.stage[0], 0) }}>
          {translatePachiResult(round.stage[0])}
        </Typography>

        <Box
          sx={{
            position: 'absolute',
            opacity: 1,
            backgroundColor: 'black',
            borderRadius: '100%',
            content: '""',
            overflow: 'hidden',
            ...getBallCoordinates(round.position),
          }}
        >
          <PachiCroonBall />
        </Box>
      </Box>

      <Box sx={{ mt: 4, display: 'flex', alignItems: 'center' }}>
        <PachiCroonBall />

        <Typography>
          <ArrowForward sx={{ fontSize: 26, ml: 2, mr: 2, mt: 0.75 }} />
        </Typography>

        <Typography sx={{ fontSize: 20 }}>{translatePachiResult(round.result)}</Typography>
      </Box>
    </Box>
  );
};
