import Typography, { TypographyProps } from '@mui/material/Typography';
import { useTheme } from 'hooks';

export const ShadowTypography = (props: TypographyProps) => {
  const theme = useTheme();
  return (
    <Typography
      {...props}
      sx={{ textShadow: `0px 0px 4px ${theme.palette.getContrastText(theme.palette.text.primary)}`, ...props.sx }}
    >
      {props.children}
    </Typography>
  );
};

export default ShadowTypography;
