export const getOutcome = (
  symbolsInReel: number,
  reelset: string[][],
  stopPositions: number[],
  offset = -1,
): string[][] => {
  const outcome: string[][] = [];
  reelset.forEach((reel, reelIndex) => {
    if (reel.length === 0) {
      return;
    }
    const reelOutcome: string[] = [];
    let symbolIndex = stopPositions[reelIndex]! + offset;
    if (symbolIndex < 0) {
      symbolIndex = reel.length - 1;
    }

    while (reelOutcome.length < symbolsInReel) {
      if (!reel[symbolIndex]) {
        symbolIndex = 0;
      }
      const symbol = reel[symbolIndex];
      reelOutcome.push(symbol!);
      symbolIndex += 1;
    }
    outcome.push(reelOutcome);
  });
  return outcome;
};
