import { useCallback, useEffect, useRef } from 'react';

export const useDynamicScroll = () => {
  const elementsRef = useRef<HTMLElement[]>([]);

  const setElement = useCallback((element: HTMLElement, index: number) => {
    elementsRef.current[index] = element;
  }, []);

  const clearElements = useCallback(() => {
    elementsRef.current = [];
  }, []);

  const scrollTo = useCallback((diff: number) => {
    const root = document.getElementById('layout');
    if (!root) {
      return;
    }

    if (diff === -Infinity) {
      return root.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }

    if (diff === Infinity) {
      return root.scrollTo({
        top: root.scrollHeight,
        behavior: 'smooth',
      });
    }

    const filteredElements = elementsRef.current.filter(Boolean);
    const currentElementIndex = filteredElements.reduce((index, element, i) => {
      if (element.offsetTop < root.scrollTop + 20) {
        return i;
      }
      return index;
    }, -1);

    if (currentElementIndex === 0 && diff < 0) {
      root.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      return;
    }

    const targetElement = filteredElements[currentElementIndex + diff];
    if (!targetElement) {
      return;
    }

    root.scrollTo({
      top: targetElement.offsetTop,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    const onKeyDown = (evt: KeyboardEvent) => {
      if (elementsRef.current.length < 2) {
        return;
      }
      if (evt.key === 'ArrowUp') {
        evt.preventDefault();
        scrollTo(-1);
      } else if (evt.key === 'ArrowDown') {
        evt.preventDefault();
        scrollTo(1);
      }
    };

    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  return {
    setElement,
    clearElements,
    scrollTo,
  };
};
