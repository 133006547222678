import type { IBet, IRawBet, IRawData } from 'types';
import { IPachiCroonFeatures as T, TPachiMiddleware } from './pachiCroonTypes';

export const pachiCroonMiddleware = (bet: IBet<T>, _: IRawBet<T>, rawData: IRawData<T>): IBet<T, TPachiMiddleware> => {
  const parentBetId = bet.features.bonusData?.betId;

  if (parentBetId) {
    const betResultsKeys = Object.keys(rawData.data.staticReplayBets.betResults);
    const index = betResultsKeys.indexOf(bet.id);

    const parentBet = rawData.data.staticReplayBets.betResults[parentBetId];
    const parentBetRounds = parentBet.features.bonusData?.pachiCroonRounds;
    const parentBetStage = parentBet.features.bonusData?.stage;

    const position = parentBetRounds?.[index].position;
    const stage = parentBetStage?.[index];

    if (position == null) {
      throw new Error('Wrong position supplied for the parent bet round');
    }

    if (stage == null) {
      throw new Error('Wrong stage index for the parent bet stage');
    }

    return {
      ...bet,
      additionalData: {
        type: 'stage',
        round: {
          position: position,
          result: stage[position],
          stage,
        },
      },
    };
  }

  return { ...bet, additionalData: { type: 'default' } };
};
