import { useEffect, useMemo, useState } from 'react';
import { Outcome, PaylinesList, SymbolOverlay } from 'components';
import type { IBet, IPayline } from 'types';
import { getOutcome, getPositionFromIndex, parse2RewardsPaylines } from 'utils';
import config from './pachiCroonConfig';
import { PachiCroonStage } from './PachiCroonStage';
import { IPachiCroonFeatures, TPachiMiddleware } from './pachiCroonTypes';

interface IProps {
  data: IBet<IPachiCroonFeatures, TPachiMiddleware>;
}

export const PachiCroon = ({ data }: IProps) => {
  const outcome = useMemo(
    () => getOutcome(config.SYMBOLS_IN_REEL, data.reelset, data.stopPositions),
    [data.reelset, data.stopPositions],
  );
  const [currentPayline, setCurrentPayline] = useState<IPayline | null>(null);
  useEffect(() => setCurrentPayline(null), [data]);
  const paylines = useMemo(() => parse2RewardsPaylines(data), [data]);

  if (data.additionalData?.type === 'stage') {
    return <PachiCroonStage round={data.additionalData.round} />;
  }

  return (
    <>
      <Outcome outcome={outcome} config={config}>
        {currentPayline?.winPositions.map((positionIndex) => (
          <SymbolOverlay
            key={positionIndex}
            position={getPositionFromIndex(positionIndex, config.REELS_COUNT)}
            config={config}
            sx={{ backgroundColor: 'primary.main', opacity: 0.5 }}
          />
        ))}
      </Outcome>
      <PaylinesList
        paylines={paylines}
        coinValue={data.coinValue}
        coinAmount={data.coinAmount}
        currency={data.currency}
        currentPayline={currentPayline}
        setCurrentPayline={setCurrentPayline}
      />
    </>
  );
};

export default PachiCroon;
