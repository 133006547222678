import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { expandFreeSpinsAtom, isBuyFeatureTriggerAtom } from 'atoms';
import CenterBox from 'components/CenterBox';
import { useAtomValue, useSetAtom } from 'jotai';
import { Error, NavigationArrows, Paytable, RoundTitle, Title } from './components';
import games from './games';
import { useConnection, useDynamicScroll } from './hooks';

export const GameHistory = () => {
  const { t } = useTranslation();
  const { data, loading, error, fetchData, betId, expandFreeSpins, setLoading } = useConnection();
  const { setElement, clearElements, scrollTo } = useDynamicScroll();
  const isBuyFeatureTrigger = useAtomValue(isBuyFeatureTriggerAtom);
  const setExpandFreeSpins = useSetAtom(expandFreeSpinsAtom);

  useEffect(() => {
    clearElements();
  }, [betId, expandFreeSpins]);

  if (error) {
    return <Error error={error} onButtonClick={() => fetchData(betId, expandFreeSpins)} />;
  }

  if (loading) {
    return (
      <CenterBox>
        <CircularProgress />
      </CenterBox>
    );
  }

  const game = games[data?.slotId || ''];

  if (!data) {
    return null;
  }

  if (!game) {
    return (
      <CenterBox>
        <Typography textAlign="center" variant="h4">
          {t('gameNotFound')}
        </Typography>
      </CenterBox>
    );
  }

  const TitleComponent = game.Title || Title;
  const RoundTitleComponent = game.RoundTitle || RoundTitle;
  const PaytableComponent = game.Paytable || Paytable;
  const GameComponent = game.Component;

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
        <TitleComponent data={data} gameName={game.name} showTotalWin={expandFreeSpins} />
        {isBuyFeatureTrigger && !expandFreeSpins ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
            <Typography variant="h6">{t('buyFeatureTrigger')}</Typography>
            <Button
              variant="contained"
              onClick={() => {
                setExpandFreeSpins(true);
                setLoading(true);
              }}
            >
              {t('expandFreeSpins')}
            </Button>
          </Box>
        ) : (
          data.bets.map((bet, index) => (
            <Fragment key={bet.id}>
              <RoundTitleComponent
                ref={(ref) => ref && setElement(ref as HTMLElement, index)}
                data={bet}
                sticky={game.stickyTitle}
                index={index}
                betsCount={data.bets.length}
              />
              <GameComponent data={bet} />
            </Fragment>
          ))
        )}
      </Box>
      <PaytableComponent />
      {data.bets.length > 1 && <NavigationArrows scrollTo={scrollTo} />}
      <Box sx={{ minHeight: 200, minWidth: 10 }} />
    </>
  );
};

export default GameHistory;
