import { IBet, IRawBet } from 'types';

interface IRawBetFeatures {
  betWithBonus?: boolean;
}

export const pachincaMiddleware = (bet: IBet, rawBet: IRawBet) => {
  const rawFeatures = rawBet.data.features as IRawBetFeatures;
  if (rawFeatures.betWithBonus) {
    const buyFeatureCost = 90;
    bet.coinAmount = Math.round(bet.coinAmount / buyFeatureCost);
  }

  return bet;
};
