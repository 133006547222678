import Box from '@mui/material/Box';
import backgroundImg from './assets/progress_bar_base.png';
import progressCircleImg from './assets/progress_circle.png';
import progressFillActiveImg from './assets/progress_fill_active.png';
import progressFillImg from './assets/progress_fill.png';
import type { IProgressBarProps } from './MeteorOfChaos';

const levels = ['', '', '', '5', '10', '25', '50', '100'];

const activeTextColor = '#421e00';
const notActiveTextColor = '#8f8261';

export const ProgressBarBase = ({ currentLevel, endLevel }: IProgressBarProps) => {
  return (
    <Box sx={{ position: 'absolute', width: 434, height: 60, left: 0, top: 1 }}>
      <img src={backgroundImg} style={{ width: '100%', height: '100%' }} />

      {levels.map((level, index) => (
        <Box
          key={index}
          sx={{
            position: 'absolute',
            left: 13 + index * 50,
            top: 7,
            display: 'flex',
            fontSize: 18,
            justifyContent: 'center',
            alignItems: 'center',
            width: 48,
            height: 48,
            borderRadius: 48,
          }}
        >
          <img
            style={{ width: '100%', height: '100%', position: 'absolute' }}
            src={currentLevel > index ? progressFillActiveImg : progressFillImg}
          />
          <img style={{ width: '100%', height: '100%', position: 'absolute' }} src={progressCircleImg} />
          <Box
            sx={{
              position: 'absolute',
              color: currentLevel > index ? (endLevel - 1 === index ? 'red' : activeTextColor) : notActiveTextColor,
              fontWeight: 'bold',
            }}
          >
            {level}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default ProgressBarBase;
