export const query = `query StaticReplayBets($betId: UUID!, $expandFreeSpins: Boolean!) {
  staticReplayBets(
    input: { betId: $betId, expandFreeSpins: $expandFreeSpins }
  ) {
    relatedBets {
      originalBetId
      related {
        id
      }
    }
    bets {
      id
      coinAmount
      coinValue
      slotId
      status
      userBonus {
        id
        bonusId
      }
      reelSetId
      data
      createdAt
      updatedAt
      result {
        reelPositions
        winCoinAmount
      }
    }
    transactions
    bonusTransactions
    betResults
    nextBetIds
    slot {
      id
      reels {
        id
        layout
      }
      lineSets {
        id
        coinAmountMultiplier
      }
      icons {
        id
        type
        combos {
          type
          pattern
          payoffType
          rewards {
            type
            multiplier
            count
            bonusId
          }
        }
      }
    }
  }
}`;
