import { useEffect, useMemo, useState } from 'react';
import type { SxProps } from '@mui/material';
import { Outcome, PaylinesList, SymbolOverlay } from 'components';
import type { IBet, IPayline } from 'types';
import { getOutcome, getPositionFromIndex, parse2RewardsPaylines } from 'utils';
import config from './spinHeistConfig';
import { SpinHeistSpinGrabFeature } from './SpinHeistSpinGrabFeature';
import type { ISpinAndGrabFeature, ISpinAndGrabSlot, ISpinHeistFeatures } from './spinHeistTypes';

export type TMiddlewareSpecificSpinHeist =
  | {
      mode: 'default';
    }
  | {
      mode: 'spinAndGrabMainBet';
      cells: ISpinAndGrabSlot[];
    }
  | {
      mode: 'spinAndGrab';
      spinGrabFeature: ISpinAndGrabFeature;
    };

export interface ISpinHeistProps {
  data: IBet<ISpinHeistFeatures, TMiddlewareSpecificSpinHeist>;
}

export const symbolSxHeistProps: SxProps = { color: 'gold', fontSize: 32, WebkitTextStroke: '1px black' };

export const SpinHeist = ({ data }: ISpinHeistProps) => {
  const outcome = useMemo(
    () => getOutcome(config.SYMBOLS_IN_REEL, data.reelset, data.stopPositions),
    [data.reelset, data.stopPositions],
  );

  const [currentPayline, setCurrentPayline] = useState<IPayline | null>(null);
  useEffect(() => setCurrentPayline(null), [data]);
  const paylines = useMemo(() => parse2RewardsPaylines(data), [data]);

  if (data.additionalData?.mode === 'spinAndGrab') {
    return <SpinHeistSpinGrabFeature spinAndGrabFeature={data.additionalData.spinGrabFeature} />;
  }

  return (
    <>
      <Outcome outcome={outcome} config={config}>
        {currentPayline?.winPositions.map((positionIndex) => (
          <SymbolOverlay
            key={positionIndex}
            position={getPositionFromIndex(positionIndex, config.REELS_COUNT)}
            config={config}
            sx={{ backgroundColor: 'primary.main', opacity: 0.5 }}
          />
        ))}

        {Object.entries(data.features.moneyCollectFeature?.moneySymbols || {}).map(([k, money]) => {
          return (
            <SymbolOverlay
              key={k}
              config={config}
              position={getPositionFromIndex(Number(money.position), config.REELS_COUNT)}
              sx={symbolSxHeistProps}
            >
              x{money.value}
            </SymbolOverlay>
          );
        })}

        {Object.entries(data.features.moneyCollectFeature?.collectSymbols || {}).map(([k, money]) => {
          return (
            <SymbolOverlay
              key={k}
              config={config}
              position={getPositionFromIndex(Number(money.position), config.REELS_COUNT)}
              sx={symbolSxHeistProps}
            >
              x{money.value}
            </SymbolOverlay>
          );
        })}

        {data.features.gameRoundStore && (
          <SymbolOverlay config={config} position={getPositionFromIndex(9, config.REELS_COUNT)} sx={symbolSxHeistProps}>
            x{data.features.gameRoundStore?.totalMoneyCollectValue}
          </SymbolOverlay>
        )}

        {data.additionalData?.mode === 'spinAndGrabMainBet' &&
          Object.entries(data.additionalData.cells).map(([_, v]) => {
            return (
              <SymbolOverlay
                key={v.position}
                config={config}
                position={getPositionFromIndex(v.position, config.REELS_COUNT)}
                sx={symbolSxHeistProps}
              >
                x{v.value}
              </SymbolOverlay>
            );
          })}
      </Outcome>
      <PaylinesList
        paylines={paylines}
        coinValue={data.coinValue}
        coinAmount={data.coinAmount}
        currency={data.currency}
        currentPayline={currentPayline}
        setCurrentPayline={setCurrentPayline}
      />
    </>
  );
};

export default SpinHeist;
