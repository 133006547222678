import { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { Outcome, PaylinesList, SymbolOverlay } from 'components';
import type { IBet, IPayline } from 'types';
import { getOutcome, getPositionFromIndex, parse2RewardsPaylines } from 'utils';
import config from './sharkRushConfig';
import { SharkRushLevelComponent } from './SharkRushLevelComponent';
import type { ISharkRushFeatures } from './sharkRushTypes';

interface IProps {
  data: IBet<ISharkRushFeatures>;
}

export const SharkRush = ({ data }: IProps) => {
  const outcome = useMemo(
    () => getOutcome(config.SYMBOLS_IN_REEL, data.reelset, data.stopPositions),
    [data.reelset, data.stopPositions],
  );
  const [currentPayline, setCurrentPayline] = useState<IPayline | null>(null);
  useEffect(() => setCurrentPayline(null), [data]);
  const paylines = useMemo(() => parse2RewardsPaylines(data), [data]);

  return (
    <>
      <Box sx={{ position: 'relative', width: '100%' }}>
        <SharkRushLevelComponent data={data} />
      </Box>

      <Outcome outcome={outcome} config={config}>
        {currentPayline?.winPositions.map((positionIndex) => (
          <SymbolOverlay
            key={positionIndex}
            position={getPositionFromIndex(positionIndex, config.REELS_COUNT)}
            config={config}
            sx={{ backgroundColor: 'primary.main', opacity: 0.5 }}
          />
        ))}
      </Outcome>
      <PaylinesList
        paylines={paylines}
        coinValue={data.coinValue}
        coinAmount={data.coinAmount}
        currency={data.currency}
        currentPayline={currentPayline}
        setCurrentPayline={setCurrentPayline}
      />
    </>
  );
};

export default SharkRush;
