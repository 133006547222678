import { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import { Outcome, PaylinesList, SymbolOverlay } from 'components';
import type { IBet, IPayline } from 'types';
import { getOutcome, getPositionFromIndex, parse2RewardsPaylines } from 'utils';
import config from './goldenPirateConfig';
import type { IGoldenPriateFeatures } from './goldenPirateFeatures';

interface IProps {
  data: IBet<IGoldenPriateFeatures>;
}

export const GoldenPirate = ({ data }: IProps) => {
  const outcome = useMemo(
    () => getOutcome(config.SYMBOLS_IN_REEL, data.reelset, data.stopPositions),
    [data.reelset, data.stopPositions],
  );
  const [currentPayline, setCurrentPayline] = useState<IPayline | null>(null);
  useEffect(() => setCurrentPayline(null), [data]);

  const paylines = useMemo(() => parse2RewardsPaylines(data), [data]);

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 3,
        marginTop: 3,
      }}
    >
      {data.features.multiplier > 0 && (
        <Box
          sx={{
            position: 'absolute',
            right: 16,
            top: -57,
            fontSize: 48,
            color: 'text.primary',
          }}
        >
          x{data.features.multiplier}
        </Box>
      )}

      <Outcome outcome={outcome} config={config}>
        {currentPayline?.winPositions.map((positionIndex) => (
          <SymbolOverlay
            key={positionIndex}
            position={getPositionFromIndex(positionIndex, config.REELS_COUNT)}
            config={config}
            sx={{ backgroundColor: 'primary.main', opacity: 0.5 }}
          />
        ))}
      </Outcome>
      <PaylinesList
        multiplier={data.features.multiplier || 1}
        paylines={paylines}
        coinValue={data.coinValue}
        currency={data.currency}
        coinAmount={data.coinAmount}
        currentPayline={currentPayline}
        setCurrentPayline={setCurrentPayline}
      />
    </Box>
  );
};

export default GoldenPirate;
