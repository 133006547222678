import type { IGameConfig } from 'types';
import ace from './assets/ace.png';
import anubis from './assets/anubis.png';
import eye from './assets/eye.png';
import jack from './assets/jack.png';
import king from './assets/king.png';
import man from './assets/man.png';
import mask from './assets/mask.png';
import Mystery01 from './assets/Mystery01.png';
import Mystery02 from './assets/Mystery02.png';
import nine from './assets/nine.png';
import pyramid from './assets/pyramid.png';
import queen from './assets/queen.png';
import ra from './assets/ra.png';
import scarab from './assets/scarab.png';
import ten from './assets/ten.png';

enum symbols {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  MS1 = 'MS1',
  MS2 = 'MS2',
  WL = 'WL',
  SC1 = 'SC1',
  SC2 = 'SC2',
}

const assets: Record<symbols, string> = {
  [symbols.A]: mask,
  [symbols.B]: ra,
  [symbols.C]: anubis,
  [symbols.D]: scarab,
  [symbols.E]: eye,
  [symbols.F]: ace,
  [symbols.G]: king,
  [symbols.H]: queen,
  [symbols.I]: jack,
  [symbols.J]: ten,
  [symbols.K]: nine,
  [symbols.SC1]: pyramid,
  [symbols.SC2]: pyramid,
  [symbols.WL]: man,
  [symbols.MS1]: Mystery01,
  [symbols.MS2]: Mystery02,
};

const config: IGameConfig = {
  REELS_COUNT: 5,
  SYMBOLS_IN_REEL: 3,
  SYMBOL_WIDTH: 96,
  SYMBOL_HEIGHT: 96,
  symbols,
  assets,
};

export default config;
