import { atom, getDefaultStore } from 'jotai';

const params = new URLSearchParams(window.location.search);
const initialBetId = params.get('betId') || '';
const initialExpandFreeSpins = params.get('expandFreeSpins') === 'true';
const darkModeInQueryParams = params.get('darkMode');
const initLang = params.get('lang') || navigator.language.slice(0, 2);
const initialDarkMode = darkModeInQueryParams
  ? darkModeInQueryParams === 'true'
  : window.matchMedia('(prefers-color-scheme: dark)').matches;
const initialBuyFeatureTrigger = params.get('bft') === 'true';

export const betIdAtom = atom(initialBetId);
export const expandFreeSpinsAtom = atom(initialExpandFreeSpins);
export const darkThemeAtom = atom(initialDarkMode);
export const langAtom = atom(initLang);
export const elevationAtom = atom(0);
export const isBuyFeatureTriggerAtom = atom(initialBuyFeatureTrigger);
export const loadingAtom = atom(false);

const store = getDefaultStore();
window.addEventListener('message', (evt) => {
  if (evt.data?.type === 'betId') {
    store.set(loadingAtom, true);
    store.set(betIdAtom, evt.data.value);
  } else if (evt.data?.type === 'expandFreeSpins') {
    store.set(loadingAtom, true);
    store.set(expandFreeSpinsAtom, evt.data.value);
  } else if (evt.data?.type === 'darkMode') {
    store.set(darkThemeAtom, evt.data.value);
  } else if (evt.data?.type === 'lang') {
    store.set(langAtom, evt.data.value);
  } else if (evt.data?.type === 'elevation') {
    store.set(elevationAtom, evt.data.value);
  } else if (evt.data?.type === 'bft') {
    store.set(loadingAtom, true);
    store.set(isBuyFeatureTriggerAtom, evt.data.value);
  }
});
