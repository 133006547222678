import type { PropsWithChildren } from 'react';
import type { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import { paytableAtom } from 'atoms';
import { useSetAtom } from 'jotai';
import type { IGameConfig } from 'types';

interface IProps extends PropsWithChildren {
  config: IGameConfig;
  outcome: string[][];
  borderColor?: string;
  disablePaytable?: boolean;
  sx?: SxProps;
}

export const Outcome = ({ outcome, config, children, borderColor, disablePaytable, sx }: IProps) => {
  const setPaytable = useSetAtom(paytableAtom);

  return (
    <Box
      onClick={(evt) => evt.stopPropagation()}
      sx={{
        display: 'flex',
        position: 'relative',
        border: '1px solid',
        borderColor: borderColor || 'primary.action',
        ...sx,
      }}
    >
      {outcome.map((reel, reelIndex) => (
        <Box sx={{ display: 'flex', flexDirection: 'column' }} key={reelIndex}>
          {reel.map((symbol, symbolIndex) => (
            <Box
              onClick={
                disablePaytable
                  ? undefined
                  : () =>
                      setPaytable((currentPaytable) =>
                        symbol === currentPaytable?.symbol ? null : { symbol, gameConfig: config },
                      )
              }
              sx={{
                width: config.SYMBOL_WIDTH,
                height: config.SYMBOL_HEIGHT,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                border: '1px solid',
                borderColor: borderColor || 'primary.action',
                cursor: disablePaytable ? 'default' : 'pointer',
              }}
              key={symbolIndex}
            >
              {symbol && <img style={{ width: '100%', height: '100%' }} src={config.assets[symbol]} />}
            </Box>
          ))}
        </Box>
      ))}
      {children}
    </Box>
  );
};

export default Outcome;
