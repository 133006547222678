import Box from '@mui/material/Box';
import backgroundImg from './assets/progress_bar_free_spins.png';
import progressCircleImg from './assets/progress_circle.png';
import progressFillActiveImg from './assets/progress_fill_active.png';
import progressFillImg from './assets/progress_fill.png';
import type { IProgressBarProps } from './MeteorOfChaos';

const levels = [
  { level: 'x2', size: 48, left: 37, top: 42, fontSize: 18 },
  { level: 'x3', size: 56, left: 99, top: 42, fontSize: 24 },
  { level: 'x5', size: 64, left: 168, top: 42, fontSize: 32 },
  { level: 'x7', size: 72, left: 244, top: 42, fontSize: 36 },
  { level: 'x10', size: 80, left: 324, top: 42, fontSize: 32 },
];

const activeTextColor = '#421e00';
const notActiveTextColor = '#8f8261';

export const ProgressBarFreeSpins = ({ currentLevel, endLevel }: IProgressBarProps) => {
  return (
    <Box sx={{ position: 'absolute', width: 387, height: 80, left: 0, top: -15 }}>
      <img src={backgroundImg} style={{ width: '100%', height: '100%' }} />

      {levels.map((el, index) => (
        <Box
          key={el.level}
          sx={{
            position: 'absolute',
            left: el.left,
            top: el.top,
            display: 'flex',
            fontSize: el.fontSize,
            justifyContent: 'center',
            alignItems: 'center',
            width: el.size,
            height: el.size,
            borderRadius: el.size,
            transform: 'translate(-50%, -50%)',
          }}
        >
          <img
            style={{ width: '100%', height: '100%', position: 'absolute' }}
            src={currentLevel > index ? progressFillActiveImg : progressFillImg}
          />
          <img style={{ width: '100%', height: '100%', position: 'absolute' }} src={progressCircleImg} />
          <Box
            sx={{
              position: 'absolute',
              color: endLevel - 1 === index ? 'red' : currentLevel > index ? activeTextColor : notActiveTextColor,
              fontWeight: 'bold',
            }}
          >
            {el.level}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default ProgressBarFreeSpins;
