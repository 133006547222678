import type { IGameConfig } from 'types';
import A from './assets/symbol_A.png';
import B from './assets/symbol_B.png';
import BL from './assets/symbol_BL.png';
import C from './assets/symbol_C.png';
import D from './assets/symbol_D.png';
import E from './assets/symbol_E.png';
import SC from './assets/symbol_SC.png';

enum symbols {
  A = 'A',
  B = 'B',
  C = 'C',
  S = 'S',
  G = 'G',
  F = 'F',
  X = 'X',
}

const assets: Record<symbols, string> = {
  [symbols.A]: A,
  [symbols.B]: B,
  [symbols.C]: C,
  [symbols.S]: D,
  [symbols.G]: E,
  [symbols.F]: SC,
  [symbols.X]: BL,
};

const config: IGameConfig = {
  REELS_COUNT: 3,
  SYMBOLS_IN_REEL: 1,
  SYMBOL_WIDTH: 125,
  SYMBOL_HEIGHT: 125,
  symbols,
  assets,
};

export default config;
