import { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Outcome, PaylinesList, SymbolOverlay } from 'components';
import type { IBet, IPayline } from 'types';
import { getOutcome, getPositionFromIndex } from 'utils';
import meteorImg from './assets/meteor.png';
import config from './meteorOfChaosConfig';
import type { IMeteorOfChaosFeatures } from './meteorOfChaosTypes';
import ProgressBarBase from './ProgressBarBase';
import ProgressBarFreeSpins from './ProgressBarFreeSpins';
import ProgressBarRageMode from './ProgressBarRageMode';

export interface IProgressBarProps {
  currentLevel: number;
  endLevel: number;
}

interface IProps {
  data: IBet<IMeteorOfChaosFeatures>;
}

interface ICascadeStep {
  outcome: string[][];
  paylines: IPayline[];
  multiplier: number;
  randomWildsCount: number;
  isRage: boolean;
}

const multipliers = [1, 2, 3, 5, 7, 10];

const deduceOutcomes = (data: IBet<IMeteorOfChaosFeatures>): ICascadeStep[] => {
  let currentOutcome = getOutcome(config.SYMBOLS_IN_REEL, data.reelset, data.stopPositions, 0);

  // add empty cascade to show final state
  const bonusPayline = data.paylines.find((payline) => payline.rewards.some((el) => el.type === 'BONUS'));
  const lastStep = {
    winAmounts: [0],
    winPositions: [],
    multiplier: data.features.multiplier,
    isRage: data.features.cascade[data.features.cascade.length - 1]?.isRage,
    isRandomWilds: false,
    cascadeFall: [],
  };

  const multiplier = data.features.multiplier === 10 ? 10 : data.bonusId ? multipliers[data.features.cascadeSteps] : 1;
  const steps = [...data.features.cascade, lastStep].map((cascade) => {
    const outcome = currentOutcome.map((reel) => [...reel]);

    let paylines: IPayline[] = cascade.winPositions.map((winPositions, winPositionIndex) => {
      winPositions.forEach((positionIndex) => {
        const position = getPositionFromIndex(positionIndex, config.REELS_COUNT);
        currentOutcome[position.x][position.y] = '';
      });
      return {
        winPositions,
        rewards: [{ type: 'COINS', multiplier: cascade.winAmounts[winPositionIndex] * multiplier }],
      };
    });

    if (cascade === lastStep && bonusPayline) {
      paylines = [bonusPayline];
    }

    currentOutcome = currentOutcome.map((reel) => reel.filter(Boolean));
    currentOutcome.forEach((reel) => {
      while (reel.length < config.SYMBOLS_IN_REEL) {
        reel.unshift('');
      }
    });

    let randomWildsCount = 0;
    cascade.cascadeFall.forEach((row, columnIndex) => {
      row.forEach((symbol, rowIndex) => {
        if (symbol) {
          currentOutcome[rowIndex][columnIndex] = symbol;
          if (cascade.isRandomWilds) {
            randomWildsCount++;
          }
        }
      });
    });

    return {
      isRage: cascade.isRage,
      outcome,
      multiplier,
      paylines,
      randomWildsCount,
    };
  });

  return steps;
};

export const MeteorOfChaos = ({ data }: IProps) => {
  const outcomes = useMemo(() => deduceOutcomes(data), [data]);

  const [currentPayline, setCurrentPayline] = useState<IPayline | null>(null);
  useEffect(() => setCurrentPayline(null), [data]);

  const endLevel = data.features.cascadeSteps;
  const getProgressBar = (isRage: boolean, index: number) => {
    if (!data.bonusId) {
      return <ProgressBarBase currentLevel={Math.min(endLevel, index)} endLevel={endLevel} />;
    }
    if (isRage) {
      return <ProgressBarRageMode />;
    }
    return <ProgressBarFreeSpins currentLevel={Math.min(endLevel, index)} endLevel={endLevel} />;
  };

  return (
    <>
      {outcomes.map((step, index) => (
        <Box
          key={index}
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
            mb: 2,
          }}
        >
          {
            <>
              {getProgressBar(step.isRage, index)}
              {/* <ProgressBar currentLevel={Math.min(endLevel, index)} endLevel={endLevel} /> */}
              <Box sx={{ height: 50 }} />
            </>
          }
          <Outcome outcome={step.outcome} config={config}>
            {currentPayline &&
              step.paylines.includes(currentPayline) &&
              currentPayline?.winPositions.map((positionIndex) => (
                <SymbolOverlay
                  key={positionIndex}
                  position={getPositionFromIndex(positionIndex, config.REELS_COUNT)}
                  config={config}
                  sx={{ backgroundColor: 'primary.main', zIndex: -1 }}
                />
              ))}
          </Outcome>
          <PaylinesList
            coinValue={data.coinValue}
            coinAmount={1}
            currency={data.currency}
            paylines={step.paylines}
            currentPayline={currentPayline}
            setCurrentPayline={setCurrentPayline}
          />
          {step.randomWildsCount > 0 && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <img src={meteorImg} style={{ width: 95, height: 90 }} />
              <Typography variant="h3">x{step.randomWildsCount}</Typography>
            </Box>
          )}
        </Box>
      ))}
    </>
  );
};

export default MeteorOfChaos;
