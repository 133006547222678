import type { IGameConfig } from 'types';
import Symbol_A_Gold7 from './assets/Symbol_A_Gold7.png';
import Symbol_B_Red7 from './assets/Symbol_B_Red7.png';
import Symbol_C_Respin from './assets/Symbol_C_Respin.png';
import Symbol_D_Hero from './assets/Symbol_D_Hero.png';
import Symbol_E_Heroine from './assets/Symbol_E_Heroine.png';
import Symbol_F_Rival from './assets/Symbol_F_Rival.png';
import Symbol_G_Arms from './assets/Symbol_G_Arms.png';
import Symbol_G_ArmsRe from './assets/Symbol_G_ArmsRe.png';
import Symbol_H_Low1 from './assets/Symbol_H_Low1.png';
import Symbol_I_Low2 from './assets/Symbol_I_Low2.png';
import Symbol_J_Low3 from './assets/Symbol_J_Low3.png';

enum symbols {
  J = 'J', // G7
  K = 'K', // R7
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H', // RP
  I = 'I', // DRP
}

const assets: Record<symbols, string> = {
  [symbols.J]: Symbol_A_Gold7,
  [symbols.K]: Symbol_B_Red7,
  [symbols.A]: Symbol_D_Hero,
  [symbols.B]: Symbol_E_Heroine,
  [symbols.C]: Symbol_F_Rival,
  [symbols.D]: Symbol_G_Arms,
  [symbols.E]: Symbol_H_Low1,
  [symbols.F]: Symbol_I_Low2,
  [symbols.G]: Symbol_J_Low3,
  [symbols.H]: Symbol_C_Respin,
  [symbols.I]: Symbol_G_ArmsRe,
};

const config: IGameConfig = {
  REELS_COUNT: 3,
  SYMBOLS_IN_REEL: 3,
  SYMBOL_WIDTH: 205,
  SYMBOL_HEIGHT: 120,
  symbols,
  assets,
};

export default config;
