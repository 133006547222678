import type { IGameConfig } from 'types';
import Symbol_A from './assets/symbol_a_bonus.png';
import Symbol_B from './assets/symbol_b_blank.png';

enum symbols {
  A = 'A',
  B = 'B',
}

const assets: Record<symbols, string> = {
  [symbols.A]: Symbol_A,
  [symbols.B]: Symbol_B,
};

const config: IGameConfig = {
  REELS_COUNT: 3,
  SYMBOLS_IN_REEL: 1,
  SYMBOL_WIDTH: 200,
  SYMBOL_HEIGHT: 120,
  symbols,
  assets,
};

export default config;
