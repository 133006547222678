import type { IGameConfig } from 'types';
import Symbol_A_Ninja from './assets/Symbol_A_Ninja.png';
import Symbol_A_Ninjax3 from './assets/Symbol_A_Ninjax3.png';
import Symbol_B_Ninja from './assets/Symbol_B_Ninja.png';
import Symbol_B_Ninjax3 from './assets/Symbol_B_Ninjax3.png';
import Symbol_C_Ninja from './assets/Symbol_C_Ninja.png';
import Symbol_C_Ninjax3 from './assets/Symbol_C_Ninjax3.png';
import Symbol_D_Ninjasword from './assets/Symbol_D_Ninjasword.png';
import Symbol_E_Chainsickle from './assets/Symbol_E_Chainsickle.png';
import Symbol_F_Kunai from './assets/Symbol_F_Kunai.png';
import Symbol_G_Makibishi from './assets/Symbol_G_Makibishi.png';
import Symbol_S_C_Shuriken from './assets/Symbol_S_C_Shuriken.png';
import Symbol_W_L_Roll from './assets/Symbol_W_L_Roll.png';

enum symbols {
  A = 'A',
  Ax3 = 'Ax3',
  B = 'B',
  Bx3 = 'Bx3',
  C = 'C',
  Cx3 = 'Cx3',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  WL = 'WL',
  SC1 = 'SC1',
}

const assets: Record<symbols, string> = {
  [symbols.A]: Symbol_A_Ninja,
  [symbols.Ax3]: Symbol_A_Ninjax3,
  [symbols.B]: Symbol_B_Ninja,
  [symbols.Bx3]: Symbol_B_Ninjax3,
  [symbols.C]: Symbol_C_Ninja,
  [symbols.Cx3]: Symbol_C_Ninjax3,
  [symbols.D]: Symbol_D_Ninjasword,
  [symbols.E]: Symbol_E_Chainsickle,
  [symbols.F]: Symbol_F_Kunai,
  [symbols.G]: Symbol_G_Makibishi,
  [symbols.WL]: Symbol_W_L_Roll,
  [symbols.SC1]: Symbol_S_C_Shuriken,
};

const config: IGameConfig = {
  REELS_COUNT: 5,
  SYMBOLS_IN_REEL: 3,
  SYMBOL_WIDTH: 96,
  SYMBOL_HEIGHT: 96,
  symbols,
  assets,
};

export default config;
