import type { IGameConfig } from 'types';
import cherry from './assets/cherry.png';
import grape from './assets/grape.png';
import lemon from './assets/lemon.png';
import orange from './assets/orange.png';
import peach from './assets/peach.png';
import scatter from './assets/scatter.png';
import seven from './assets/seven.png';
import watermelon from './assets/watermelon.png';
import wild from './assets/wild.png';

enum symbols {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  WL = 'WL',
  SC1 = 'SC1',
}

const assets: Record<symbols, string> = {
  [symbols.A]: seven,
  [symbols.B]: grape,
  [symbols.C]: watermelon,
  [symbols.D]: peach,
  [symbols.E]: lemon,
  [symbols.F]: orange,
  [symbols.G]: cherry,
  [symbols.SC1]: scatter,
  [symbols.WL]: wild,
};

const config: IGameConfig = {
  REELS_COUNT: 5,
  SYMBOLS_IN_REEL: 3,
  SYMBOL_WIDTH: 96,
  SYMBOL_HEIGHT: 96,
  symbols,
  assets,
};

export default config;
