import type { IGameConfig } from 'types';
import D1 from './assets/D1.png';
import D2 from './assets/D2.png';
import D3 from './assets/D3.png';
import D4 from './assets/D4.png';
import D5 from './assets/D5.png';

enum symbols {
  D1 = 'D1',
  D2 = 'D2',
  D3 = 'D3',
  D4 = 'D4',
  D5 = 'D5',
}

const assets: Record<symbols, string> = {
  [symbols.D1]: D1,
  [symbols.D2]: D2,
  [symbols.D3]: D3,
  [symbols.D4]: D4,
  [symbols.D5]: D5,
};

const config: IGameConfig = {
  REELS_COUNT: 1,
  SYMBOLS_IN_REEL: 1,
  SYMBOL_WIDTH: 196,
  SYMBOL_HEIGHT: 96,
  symbols,
  assets,
};

export default config;
