import { useEffect, useMemo, useState } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Outcome, PaylinesList, SymbolOverlay } from 'components';
import { useParentBet } from 'hooks';
import { TFunction } from 'i18next';
import type { IBet, IPayline } from 'types';
import { getFormattedMoney, getOutcome, getPositionFromIndex, parse2RewardsPaylines } from 'utils';
import highAttackImg from './assets/B_Bicon_Heroattack_High.png';
import lowAttackImg from './assets/B_Bicon_Heroattack_Low.png';
import mediumAttackImg from './assets/B_Bicon_Heroattack_Med.png';
import superAttackImg from './assets/B_Bicon_Heroattack_Sp.png';
import rivalAttackImg from './assets/B_Bicon_Rivalattack.png';
import scenarioImg from './assets/MessageBanner_BB_icon.png';
import config from './samuraiRumbleConfig';
import type { ISamuraiRumbleFeatures } from './samuraiRumbleTypes';

const attacks: Record<number, string> = {
  0: rivalAttackImg,
  1: lowAttackImg,
  2: mediumAttackImg,
  3: highAttackImg,
  4: superAttackImg,
  5: superAttackImg,
  6: superAttackImg,
  7: superAttackImg,
  8: superAttackImg,
  9: superAttackImg,
  10: superAttackImg,
};

interface IProps {
  data: IBet<ISamuraiRumbleFeatures>;
}

const getPaylineLabel = (
  t: TFunction,
  payline: IPayline,
  coinValue: number,
  coinAmount: number,
  currency: string,
  multiplier?: number,
) => {
  const reward = payline.rewards[0];
  if (!reward) {
    return t('noWinPayline');
  }
  if (reward.type === 'BONUS') {
    if (reward.count === 1) {
      return t('winRespinPayline', { count: reward.count });
    }

    return t('winBonus');
  }
  return t('winMoneyPayline', {
    win: getFormattedMoney((reward.multiplier || 0) * coinValue * coinAmount * (multiplier || 1), currency),
  });
};

export const SamuraiRumble = ({ data }: IProps) => {
  const outcome = useMemo(
    () => getOutcome(config.SYMBOLS_IN_REEL, data.reelset, data.stopPositions),
    [data.reelset, data.stopPositions],
  );
  const [currentPayline, setCurrentPayline] = useState<IPayline | null>(null);
  useEffect(() => setCurrentPayline(null), [data]);
  const paylines = useMemo(() => parse2RewardsPaylines(data), [data]);
  const { parentBetFeatures, betIndex } = useParentBet(data);
  const battleData = parentBetFeatures?.bonusData?.battleBonusRounds?.[betIndex];

  if (battleData) {
    const img = attacks[battleData.points];
    return (
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', width: '100%' }}>
        {img ? (
          <img src={img} alt="attack" style={{ width: 202, height: 112, marginTop: -20 }} />
        ) : (
          <img src={scenarioImg} alt="attack" style={{ width: 276, height: 67 }} />
        )}
        <ArrowForwardIcon fontSize="large" />
        <Typography variant="h4">
          {getFormattedMoney(
            data.coinAmount * data.coinValue * data.coinAmountMultiplier * battleData.coinReward,
            data.currency,
          )}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Outcome outcome={outcome} config={config}>
        {currentPayline?.winPositions.map((positionIndex) => (
          <SymbolOverlay
            key={positionIndex}
            position={getPositionFromIndex(positionIndex, config.REELS_COUNT)}
            config={config}
            sx={{ backgroundColor: 'primary.main', opacity: 0.5 }}
          />
        ))}
      </Outcome>
      <PaylinesList
        paylines={paylines}
        coinValue={data.coinValue}
        coinAmount={data.coinAmount}
        currency={data.currency}
        currentPayline={currentPayline}
        setCurrentPayline={setCurrentPayline}
        getPaylineLabel={getPaylineLabel}
      />
    </>
  );
};

export default SamuraiRumble;
