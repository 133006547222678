import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Outcome, PaylinesList, SymbolOverlay } from 'components';
import type { IBet, IPayline } from 'types';
import { getFormattedMoney, getOutcome, getPositionFromIndex, parse2RewardsPaylines } from 'utils';
import config from './dazzlingBoxConfig';
import type { IDazzlingBoxFeatures } from './dazzlingBoxTypes';

interface IProps {
  data: IBet<IDazzlingBoxFeatures>;
}

export const DazzlingBox = ({ data }: IProps) => {
  const { t } = useTranslation();
  const outcome = useMemo(
    () => getOutcome(config.SYMBOLS_IN_REEL, data.reelset, data.stopPositions),
    [data.reelset, data.stopPositions],
  );
  const [currentPayline, setCurrentPayline] = useState<IPayline | null>(null);
  useEffect(() => setCurrentPayline(null), [data]);
  const paylines = useMemo(() => parse2RewardsPaylines(data), [data]);

  if (outcome.length === 0) {
    return paylines.map((payline, index) => (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }} key={index}>
        <Typography key={index} variant="h4">
          {t('totalMultiplier', { count: payline.rewards[0]?.multiplier || 1 })}
        </Typography>
        <ArrowForwardIcon fontSize="large" />
        <Typography variant="h4">
          {getFormattedMoney(
            (payline.rewards[0]?.multiplier || 1) * data.coinValue * data.coinAmount * data.coinAmountMultiplier,
            data.currency,
          )}
        </Typography>
      </Box>
    ));
  }

  return (
    <>
      <Outcome outcome={outcome} config={config}>
        {currentPayline?.winPositions.map((positionIndex) => (
          <SymbolOverlay
            key={positionIndex}
            position={getPositionFromIndex(positionIndex, config.REELS_COUNT)}
            config={config}
            sx={{ backgroundColor: 'primary.main', zIndex: -1 }}
          />
        ))}
        {data.features.diamonds?.map((diamond, index) => (
          <SymbolOverlay
            key={index}
            position={getPositionFromIndex(diamond.position, config.REELS_COUNT)}
            config={config}
            sx={{
              fontSize: 32,
              color: diamond.score >= 10 ? 'red' : 'yellow',
              fontWeight: 'bold',
              WebkitTextStroke: '1px black',
            }}
          >
            {diamond.score}
          </SymbolOverlay>
        ))}
      </Outcome>
      <PaylinesList
        paylines={paylines}
        coinValue={data.coinValue}
        currency={data.currency}
        coinAmount={data.coinAmount}
        currentPayline={currentPayline}
        setCurrentPayline={setCurrentPayline}
      />
    </>
  );
};

export default DazzlingBox;
