import { useCallback, useEffect, useRef, useState } from 'react';
import { betIdAtom, dataAtom, expandFreeSpinsAtom, loadingAtom, rawDataAtom } from 'atoms';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { parseRawData } from './parseRawData';
import { query } from './query';

const getData = (betId: string, expandFreeSpins: boolean, controller: AbortController) => {
  const body = {
    operationName: 'StaticReplayBets',
    query: query,
    variables: {
      betId,
      expandFreeSpins,
    },
  };

  return fetch('/graphql', {
    body: JSON.stringify(body),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-replay-token': import.meta.env.VITE_REPLAY_TOKEN,
    } as HeadersInit,
    signal: controller.signal,
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.errors) {
        throw new Error(data.errors[0].message);
      } else {
        return data;
      }
    });
};

export const useConnection = () => {
  const setRawData = useSetAtom(rawDataAtom);
  const [data, setData] = useAtom(dataAtom);
  const [loading, setLoading] = useAtom(loadingAtom);
  const betId = useAtomValue(betIdAtom);
  const expandFreeSpins = useAtomValue(expandFreeSpinsAtom);
  const [error, setError] = useState<string | null>(null);
  const controllerRef = useRef<AbortController | null>(null);

  const fetchData = useCallback((betId: string, expandFreeSpins: boolean) => {
    controllerRef.current?.abort('abort');
    controllerRef.current = new AbortController();
    setLoading(true);
    setError(null);
    getData(betId, expandFreeSpins, controllerRef.current)
      .then((rawData) => {
        setRawData(rawData);
        setData(parseRawData(rawData));
        setLoading(false);
      })
      .catch((error) => {
        if (error !== 'abort') {
          setError(error.message);
          setLoading(false);
        }
      });
  }, []);

  useEffect(() => {
    if (betId) {
      fetchData(betId, expandFreeSpins);
    } else {
      setLoading(false);
      if (window === window.parent) {
        setError('Bet ID is not provided');
      }
    }
  }, [betId, expandFreeSpins]);

  return { data, loading, error, betId, expandFreeSpins, fetchData, setLoading };
};
