import type { IGameConfig } from 'types';
import low_10 from './assets/low_10.png';
import low_a from './assets/low_a.png';
import low_j from './assets/low_j.png';
import low_k from './assets/low_k.png';
import low_q from './assets/low_q.png';
import middle_1 from './assets/middle_1.png';
import middle_2 from './assets/middle_2.png';
import scatter from './assets/scatter.png';
import top_1 from './assets/top_1.png';
import top_2 from './assets/top_2.png';
import top_3 from './assets/top_3.png';
import top_4 from './assets/top_4.png';
import wild from './assets/wild.png';

enum symbols {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  SC = 'SC1',
  WL = 'WL',
}

const assets: Record<symbols, string> = {
  [symbols.A]: top_1,
  [symbols.B]: top_2,
  [symbols.C]: top_3,
  [symbols.D]: top_4,
  [symbols.E]: middle_1,
  [symbols.F]: middle_2,
  [symbols.G]: low_a,
  [symbols.H]: low_k,
  [symbols.I]: low_q,
  [symbols.J]: low_j,
  [symbols.K]: low_10,
  [symbols.SC]: scatter,
  [symbols.WL]: wild,
};

const config: IGameConfig = {
  REELS_COUNT: 5,
  SYMBOLS_IN_REEL: 3,
  SYMBOL_WIDTH: 96,
  SYMBOL_HEIGHT: 96,
  symbols,
  assets,
};

export default config;
