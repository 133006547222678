import { Paytable } from 'components';
import type { IData, IIcon } from 'types';
import { getFormattedMoney } from 'utils/getFormattedMoney';

const getCombos = (icon: IIcon, data: IData) => {
  const combos: string[] = [];

  if (!data.bets.length) {
    return combos;
  }

  const { coinAmountMultiplier, coinAmount, coinValue } = data.bets[0];

  if (icon.type === 'SCATTER') {
    icon.combos = icon.combos.filter((combo) => ['x2', 'x3', 'x4', 'x5'].includes(combo.pattern));
  }

  icon.combos.forEach((combo) => {
    let string = '';
    combo.rewards.forEach((reward, index) => {
      if (reward.type === 'RANDOM_REWARD') {
        return;
      }
      if (!string) {
        string += `${combo.pattern}: `;
      }
      if (index > 0) {
        string += ', ';
      }
      if (reward.type === 'COINS' && reward.multiplier) {
        string += getFormattedMoney(
          reward.multiplier * coinAmount * coinValue * (icon.type === 'SCATTER' ? coinAmountMultiplier : 1),
          data.currency,
        );
      }
      if (reward.type === 'BONUS' && reward.count) {
        string += reward.count + ' free spins';
      }
    });
    string && combos.push(string);
  });
  return combos;
};

export const DazzlingBoxPaytable = () => {
  return <Paytable getCombos={getCombos} />;
};

export default DazzlingBoxPaytable;
