import { IBet, IRawBet, IRawData } from 'types';
import { IGoldenPriateFeatures } from './goldenPirateFeatures';

const FREE_SPINS_REELSET_ID = 'd128102c-be40-4eda-a32c-1e7d603a4dc8';

export const goldenPirateMiddleware = (bet: IBet<IGoldenPriateFeatures>, rawBet: IRawBet, rawData: IRawData) => {
  if (bet.reelsetId === FREE_SPINS_REELSET_ID) {
    if (rawData.data.staticReplayBets.bets.length > 1) {
      bet.features.multiplier = rawData.data.staticReplayBets.bets.findIndex((el) => el.id === bet.id) + 1;
    } else {
      const paylinesSum = bet.paylines.reduce((acc, payline) => {
        const sum = payline.rewards.reduce((sum, reward) => sum + (reward.multiplier || 0), 0);
        return acc + sum * bet.coinAmount;
      }, 0);
      bet.features.multiplier = bet.win / paylinesSum;
    }
  }
  return bet;
};
