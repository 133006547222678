import type { PropsWithChildren } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { elevationAtom } from 'atoms';
import { useAtomValue } from 'jotai';

export const Layout = ({ children }: PropsWithChildren) => {
  const elevation = useAtomValue(elevationAtom);

  return (
    <Box
      id="layout"
      sx={{
        height: '100vh',
        padding: 1,
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Paper
        elevation={elevation}
        sx={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          left: 0,
          top: 0,
          zIndex: -100,
        }}
      />
      {children}
    </Box>
  );
};

export default Layout;
