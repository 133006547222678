import { IBet, IRawBet, IRawData } from 'types';
import { IMeteorOfChaosFeatures } from './meteorOfChaosTypes';

const buyFeatureCosts: Record<string, number> = {
  '7490f778-3d24-4c6f-ac11-91dbe332a053': 60,
  '656bd5d3-94d9-4b94-9aad-c25310aeaa14': 306,
};

export const meteorOfChaosParseBetMiddleware = (
  bet: IBet<IMeteorOfChaosFeatures>,
  rawBet: IRawBet,
  rawData: IRawData,
) => {
  bet.features = rawBet.data.features as IMeteorOfChaosFeatures;
  if (bet.features.betWithBonus) {
    const buyFeatureCost = buyFeatureCosts[bet.bonusId || ''] || 60;
    bet.coinAmount = Math.round(bet.coinAmount / buyFeatureCost);
    bet.bet = 0;
  }

  if (bet.features.multiplier === 10) {
    if (rawData.data.staticReplayBets.bets.length > 1) {
      const betIndex = rawData.data.staticReplayBets.bets.indexOf(rawBet);
      const previousBet = rawData.data.staticReplayBets.bets[betIndex - 1];
      const previousBetFeatures = previousBet?.data.features as IMeteorOfChaosFeatures;
      if (previousBetFeatures?.multiplier !== 10) {
        bet.features.cascade.forEach((cascade, index) => {
          if (index > 5) {
            cascade.isRage = true;
          }
        });
        return bet;
      }
    }

    bet.features.cascade.forEach((cascade) => {
      cascade.isRage = true;
    });
  }

  return bet;
};
