import { useEffect } from 'react';
import { dataAtom } from 'atoms';
import { Paytable } from 'components';
import { useAtomValue } from 'jotai';

export const BookOfWaifuPaytable = () => {
  const data = useAtomValue(dataAtom);

  useEffect(() => {
    const WL = data?.icons.find((el) => el.id === 'WL');
    if (WL) {
      WL.combos = [
        {
          pattern: 'x3',
          payoffType: 'LTR',
          type: 'X_OF_KIND',
          rewards: [
            {
              type: 'COINS',
              multiplier: 20,
            },
          ],
        },
        {
          pattern: 'x4',
          payoffType: 'LTR',
          type: 'X_OF_KIND',
          rewards: [
            {
              type: 'COINS',
              multiplier: 100,
            },
          ],
        },
        {
          pattern: 'x5',
          payoffType: 'LTR',
          type: 'X_OF_KIND',
          rewards: [
            {
              type: 'COINS',
              multiplier: 1000,
            },
          ],
        },
      ];
    }
  }, [data]);

  return <Paytable />;
};

export default BookOfWaifuPaytable;
