import { useEffect, useMemo, useState } from 'react';
import { Outcome, PaylinesList, SymbolOverlay } from 'components';
import type { IBet, IPayline } from 'types';
import { getOutcome, getPositionFromIndex } from 'utils';
import config from './paradiseDelightConfig';
import type { IParadiseDelightFeatures } from './paradiseDelightTypes';

interface IProps {
  data: IBet<IParadiseDelightFeatures>;
}

const parsePaylines = (data: IBet): IPayline[] => {
  const parsed: IPayline[] = [];

  data.paylines.forEach((payline) => {
    payline.rewards.forEach((reward) => {
      if (reward.type === 'RANDOM_REWARD') {
        return;
      }
      const parsedReward = { ...reward };
      parsed.push({
        ...payline,
        rewards: [parsedReward],
      });
    });
  });

  return parsed;
};

export const ParadiseDelight = ({ data }: IProps) => {
  const outcome = useMemo(
    () => getOutcome(config.SYMBOLS_IN_REEL, data.reelset, data.stopPositions),
    [data.reelset, data.stopPositions],
  );
  const [currentPayline, setCurrentPayline] = useState<IPayline | null>(null);
  useEffect(() => setCurrentPayline(null), [data]);
  const paylines = useMemo(() => parsePaylines(data), [data]);

  return (
    <>
      <Outcome outcome={outcome} config={config}>
        {currentPayline?.winPositions.map((positionIndex) => (
          <SymbolOverlay
            key={positionIndex}
            position={getPositionFromIndex(positionIndex, config.REELS_COUNT)}
            config={config}
            sx={{ backgroundColor: 'primary.main', opacity: 0.5 }}
          />
        ))}
      </Outcome>
      <PaylinesList
        paylines={paylines}
        coinValue={data.coinValue}
        coinAmount={data.coinAmount}
        currency={data.currency}
        currentPayline={currentPayline}
        setCurrentPayline={setCurrentPayline}
      />
    </>
  );
};

export default ParadiseDelight;
