import type { IBet, IPayline, IRawBet, IRawData } from 'types';
import type { ISpinHeistFeatures } from './spinHeistTypes';

export const spinHeistMiddleware = (
  bet: IBet<ISpinHeistFeatures>,
  _: IRawBet<ISpinHeistFeatures>,
  rawData: IRawData<ISpinHeistFeatures>,
) => {
  if (bet.features.bonusData && 'spinAndGrabFeature' in bet.features.bonusData) {
    return {
      ...bet,
      additionalData: {
        mode: 'spinAndGrabMainBet',
        cells: bet.features.bonusData.spinAndGrabFeature.startingCells.filter((cell) => cell.value),
      },
      paylines: reduceCoinTypeRewards(bet.paylines, bet.coinAmountMultiplier),
    };
  }

  if (bet.features.bonusData && 'betId' in bet.features.bonusData) {
    const bonusBetId = bet.features.bonusData.betId;
    const parentBet = rawData.data.staticReplayBets.betResults[bonusBetId];

    if (parentBet.features.bonusData && 'spinAndGrabFeature' in parentBet.features.bonusData) {
      return {
        ...bet,
        additionalData: { mode: 'spinAndGrab', spinGrabFeature: parentBet.features.bonusData.spinAndGrabFeature },
        paylines: reduceCoinTypeRewards(bet.paylines, bet.coinAmountMultiplier),
      };
    }
  }

  return {
    ...bet,
    additionalData: { mode: 'default' },
    paylines: reduceCoinTypeRewards(bet.paylines, bet.coinAmountMultiplier),
  };
};

const reduceCoinTypeRewards = (paylines: IPayline[], coinMultiplier: number) => {
  const modifiedPaylines = [];

  for (const payline of paylines) {
    const rewards = payline.rewards;
    const rewardsNoCoins = rewards.filter((reward) => reward.type !== 'COINS');
    const rewardsWithCoins = rewards.filter((reward) => reward.type === 'COINS');
    const reducedRewards = rewardsWithCoins.reduce(
      (acc, reward) => ({ ...reward, multiplier: (reward.multiplier || 0) + (acc.multiplier as number) }),
      {
        multiplier: 0,
        type: 'COINS',
        count: 0,
      },
    );

    if (rewardsWithCoins.length > 0) {
      rewardsNoCoins.push(reducedRewards);
    }

    if (rewardsWithCoins.length > 1) {
      (reducedRewards.multiplier as number) *= coinMultiplier;
    }

    modifiedPaylines.push({ ...payline, rewards: rewardsNoCoins });
  }

  return modifiedPaylines;
};
