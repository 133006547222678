import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import { dataAtom, paytableAtom, type IPaytableData } from 'atoms';
import { useAtom, useAtomValue } from 'jotai';
import type { IData, IIcon } from 'types';
import { getFormattedMoney } from 'utils/getFormattedMoney';

const getCombos = (icon: IIcon, data: IData) => {
  const combos: string[] = [];

  if (!data.bets.length) {
    return combos;
  }

  const { coinAmountMultiplier, coinAmount, coinValue } = data.bets[0];

  icon.combos.forEach((combo) => {
    let string = '';
    combo.rewards.forEach((reward, index) => {
      if (reward.type === 'RANDOM_REWARD') {
        return;
      }
      if (!string) {
        string += `${combo.pattern}: `;
      }
      if (index > 0) {
        string += ', ';
      }
      if (reward.type === 'COINS' && reward.multiplier) {
        string += getFormattedMoney(
          reward.multiplier * coinAmount * coinValue * (icon.type === 'SCATTER' ? coinAmountMultiplier : 1),
          data.currency,
        );
      }
      if (reward.type === 'BONUS' && reward.count) {
        string += reward.count + ' free spins';
      }
    });
    string && combos.push(string);
  });
  return combos;
};

export interface IPaytableProps {
  getCombos?: (icon: IIcon, data: IData) => string[];
}

export const Paytable = (props: IPaytableProps) => {
  const { t } = useTranslation();
  const data = useAtomValue(dataAtom);
  const [paytable, setPaytable] = useAtom(paytableAtom);
  const lastPaytable = useRef<IPaytableData | null>(null);

  useEffect(() => {
    const onClick = () => {
      setPaytable(null);
    };
    window.addEventListener('click', onClick);
    return () => window.removeEventListener('click', onClick);
  }, []);

  useEffect(() => {
    lastPaytable.current = paytable;
  }, [paytable]);

  useEffect(() => {
    data && setPaytable(null);
  }, [data]);

  const paytableToShow = paytable || lastPaytable.current;

  if (!data || !paytableToShow) {
    return null;
  }

  const { gameConfig: config, symbol } = paytableToShow;
  const icon = data.icons.find((icon) => icon.id === symbol);
  if (!icon) {
    return null;
  }
  const combos = props.getCombos?.(icon, data) || getCombos(icon, data);

  return (
    <Slide direction="down" in={Boolean(paytable)}>
      <Paper elevation={8} sx={{ position: 'fixed', top: 10, p: 2, cursor: 'pointer', zIndex: 99999 }}>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <Box
            sx={{
              minWidth: config.SYMBOL_WIDTH,
              minHeight: config.SYMBOL_HEIGHT,
              maxWidth: config.SYMBOL_WIDTH,
              maxHeight: config.SYMBOL_HEIGHT,
            }}
          >
            <img style={{ width: '100%', height: '100%' }} src={config.assets[symbol]} />
          </Box>
          <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
            {combos.map((combo) => (
              <Typography key={combo}>{combo}</Typography>
            ))}
            {combos.length === 0 && <Typography align="center">{t('noPayoutSymbol')}</Typography>}
          </Box>
        </Box>
      </Paper>
    </Slide>
  );
};

export default Paytable;
