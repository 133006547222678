import type { IGameConfig } from 'types';
import FreeSpinSymbol_A_GoldenCherry from './assets/FreeSpinSymbol_A_GoldenCherry.png';
import FreeSpinSymbol_B_Blank from './assets/FreeSpinSymbol_B_Blank.png';
import Symbol_A_Seven from './assets/Symbol_A_Seven.png';
import Symbol_B_Bar from './assets/Symbol_B_Bar.png';
import Symbol_C_Lion from './assets/Symbol_C_Lion.png';
import Symbol_D_Watermelon from './assets/Symbol_D_Watermelon.png';
import Symbol_E_Bell from './assets/Symbol_E_Bell.png';
import Symbol_F_Blue from './assets/Symbol_F_Blue.png';
import Symbol_G_Cherry from './assets/Symbol_G_Cherry.png';

enum symbols {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  GC = 'GC',
  BL = 'BL',
}

const assets: Record<symbols, string> = {
  [symbols.A]: Symbol_A_Seven,
  [symbols.B]: Symbol_B_Bar,
  [symbols.C]: Symbol_C_Lion,
  [symbols.D]: Symbol_D_Watermelon,
  [symbols.E]: Symbol_E_Bell,
  [symbols.F]: Symbol_F_Blue,
  [symbols.G]: Symbol_G_Cherry,
  [symbols.GC]: FreeSpinSymbol_A_GoldenCherry,
  [symbols.BL]: FreeSpinSymbol_B_Blank,
};

const config: IGameConfig = {
  REELS_COUNT: 3,
  SYMBOLS_IN_REEL: 3,
  SYMBOL_WIDTH: 205,
  SYMBOL_HEIGHT: 120,
  symbols,
  assets,
};

export default config;
