import { Paytable } from 'components';
import type { IData, IIcon } from 'types';
import { getFormattedMoney } from 'utils/getFormattedMoney';

const PATTERNS = ['x3', 'x4', 'x5', 'x6', 'x7', 'x8', 'x9', 'x10', 'x11', 'x12', 'x13'];
const SCATTERS_PATTERNS = ['x3', 'x4', 'x5'];

const getCombos = (icon: IIcon, data: IData) => {
  const combos: string[] = [];

  if (!data.bets.length) {
    return combos;
  }

  const { coinAmount, coinValue } = data.bets[0];
  const patterns = icon.type === 'SCATTER' ? SCATTERS_PATTERNS : PATTERNS;

  icon.combos = icon.combos.filter((combo) => patterns.includes(combo.pattern));

  icon.combos.forEach((combo) => {
    let string = '';
    combo.rewards.forEach((reward, index) => {
      if (reward.type === 'RANDOM_REWARD') {
        return;
      }
      if (!string) {
        if (combo.pattern === 'x13') {
          string += '>';
        }
        string += `${combo.pattern}: `;
      }
      if (index > 0) {
        string += ', ';
      }
      if (reward.type === 'COINS' && reward.multiplier) {
        string += getFormattedMoney(reward.multiplier * coinAmount * coinValue, data.currency);
      }
      if (reward.type === 'BONUS' && reward.count) {
        string += reward.count + ' free spins';
      }
    });
    string && combos.push(string);
  });
  return combos;
};

export const DualFusionPaytable = () => {
  return <Paytable getCombos={getCombos} />;
};

export default DualFusionPaytable;
