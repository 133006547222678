import { useEffect, useMemo, useState } from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import Box from '@mui/material/Box';
import { Outcome, PaylinesList, SymbolOverlay } from 'components';
import type { IBet, IPayline } from 'types';
import { getOutcome, getPositionFromIndex, parse2RewardsPaylines } from 'utils';
import config from './forbiddenPyramidConfig';
import type { IForbiddenPyramidFeatures } from './forbiddenPyramidTypes';

interface IProps {
  data: IBet<IForbiddenPyramidFeatures>;
}

const FREE_SPINS_REELSETS = ['069d2bda-6300-4cb6-9c97-f2779bd1c919', '5976f321-ac72-4e57-986c-b47a55eb3248'];

const deduceOutcomes = (data: IBet<IForbiddenPyramidFeatures>, replaceMysterySymbol: boolean) => {
  let hasMystery = false;

  const firstOutcome = getOutcome(config.SYMBOLS_IN_REEL, data.reelset, data.stopPositions).map((reel) =>
    reel.map((symbol) => {
      if (symbol === config.symbols.MS1) {
        hasMystery = true;
      }
      return replaceMysterySymbol && symbol === config.symbols.MS1 ? config.symbols.MS2 : symbol;
    }),
  );

  const secondOutcome = firstOutcome.map((reel) =>
    reel.map((symbol) =>
      symbol === config.symbols.MS1 || symbol === config.symbols.MS2 ? data.features.mystery.changeTo : symbol,
    ),
  );

  return { firstOutcome, secondOutcome, hasMystery };
};

export const ForbiddenPyramid = ({ data }: IProps) => {
  const replaceMysterySymbol = FREE_SPINS_REELSETS.includes(data.reelsetId);

  const { firstOutcome, secondOutcome, hasMystery } = useMemo(() => deduceOutcomes(data, replaceMysterySymbol), [data]);
  const [currentPayline, setCurrentPayline] = useState<IPayline | null>(null);
  useEffect(() => setCurrentPayline(null), [data]);
  const paylines = useMemo(() => parse2RewardsPaylines(data), [data]);

  return (
    <>
      {hasMystery && (
        <>
          <Outcome outcome={firstOutcome} config={config} />
          <ArrowDownwardIcon fontSize="large" />
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <img
              style={{ width: config.SYMBOL_WIDTH, height: config.SYMBOL_HEIGHT }}
              src={config.assets[replaceMysterySymbol ? config.symbols.MS2 : config.symbols.MS1]}
            />
            <SwapHorizIcon fontSize="large" />
            <img
              style={{ width: config.SYMBOL_WIDTH, height: config.SYMBOL_HEIGHT }}
              src={config.assets[data.features.mystery.changeTo]}
            />
          </Box>
          <ArrowDownwardIcon fontSize="large" />
        </>
      )}
      <Outcome outcome={secondOutcome} config={config}>
        {currentPayline?.winPositions.map((positionIndex) => (
          <SymbolOverlay
            key={positionIndex}
            position={getPositionFromIndex(positionIndex, config.REELS_COUNT)}
            config={config}
            sx={{ backgroundColor: 'primary.main', opacity: 0.5 }}
          />
        ))}
      </Outcome>
      <PaylinesList
        paylines={paylines}
        coinValue={data.coinValue}
        currency={data.currency}
        coinAmount={data.coinAmount}
        currentPayline={currentPayline}
        setCurrentPayline={setCurrentPayline}
      />
    </>
  );
};

export default ForbiddenPyramid;
