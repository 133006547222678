import { useTranslation } from 'react-i18next';
import { Box, Divider, Typography } from '@mui/material';
import { Outcome, SymbolOverlay } from 'components';
import { getPositionFromIndex } from 'utils';
import { createSpinGrabRounds } from './spinAndGrabFeature';
import { symbolSxHeistProps } from './SpinHeist';
import config from './spinHeistConfig';
import type { ISpinAndGrabFeature } from './spinHeistTypes';

export const SpinHeistSpinGrabFeature = ({ spinAndGrabFeature }: { spinAndGrabFeature: ISpinAndGrabFeature }) => {
  const { outcomes, values } = createSpinGrabRounds(spinAndGrabFeature);

  const totalMultiplier = values[values.length - 1].reduce((acc, value) => (value.value ? value.value + acc : acc), 0);

  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
      {outcomes.map((outcome, i) => {
        return (
          <Box key={i}>
            <Divider sx={{ mt: 1, mb: 4 }} />
            <Outcome outcome={outcome} config={config}>
              {values[i].map((value) => (
                <SymbolOverlay
                  key={value.position}
                  config={config}
                  position={getPositionFromIndex(value.position, config.REELS_COUNT)}
                  sx={symbolSxHeistProps}
                >
                  x{value.value}
                </SymbolOverlay>
              ))}
            </Outcome>

            {i === outcomes.length - 1 && (
              <Box sx={{ mt: 4 }}>
                <Typography>{t('totalMultiplier', { count: totalMultiplier })}</Typography>
                <Typography></Typography>
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
};
