import { forwardRef, type ForwardedRef } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { elevationAtom } from 'atoms';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import type { IBet } from 'types';
import { getFormattedMoney } from 'utils/getFormattedMoney';

export interface IRoundTitleProps {
  data: IBet;
  index: number;
  betsCount: number;
  sticky?: boolean;
}

const nonStickySx = { width: '100%', pt: 1, boxShadow: 'none' };

const stickySx = {
  ...nonStickySx,
  position: 'sticky',
  top: -8,
  zIndex: 1,
};

export const RoundTitle = forwardRef(
  ({ data, sticky, index, betsCount }: IRoundTitleProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { t } = useTranslation();
    const titleText = `${t('roundTitle')}${betsCount > 1 ? ' ' + (index + 1) + '/' + betsCount : ''}`;
    const elevation = useAtomValue(elevationAtom);

    const betString =
      data.bet === 0
        ? t('freeSpinBet', { bet: getFormattedMoney(0, data.currency) })
        : t('bet', { bet: getFormattedMoney(data.bet, data.currency) });

    return (
      <Paper ref={ref} sx={sticky ? stickySx : nonStickySx} elevation={elevation} square>
        <Divider>
          <Typography textAlign="center" variant="h5" color="inherit">
            {titleText}
          </Typography>
        </Divider>
        <Box sx={{ p: 1 }}>
          <Typography dangerouslySetInnerHTML={{ __html: t('betId', { id: data.id }) }} />
          <Typography
            dangerouslySetInnerHTML={{
              __html: t('betDate', { date: dayjs(data.createdAt).format('YYYY-MM-DD HH:mm:ss') }),
            }}
          />
          <Typography dangerouslySetInnerHTML={{ __html: betString }} />
          <Typography
            dangerouslySetInnerHTML={{ __html: t('betWin', { win: getFormattedMoney(data.win, data.currency) }) }}
          />
        </Box>
      </Paper>
    );
  },
);

RoundTitle.displayName = 'RoundTitle';

export default RoundTitle;
