import type { IGameConfig } from 'types';
import compass from './assets/compass.png';
import cutlass from './assets/cutlass.png';
import gun from './assets/gun.png';
import pirate_a from './assets/pirate_a.png';
import pirate_b from './assets/pirate_b.png';
import pirate_c from './assets/pirate_c.png';
import scatter from './assets/scatter.png';
import telescope from './assets/telescope.png';
import wild from './assets/wild.png';

enum symbols {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  WL = 'WL',
  SC1 = 'SC1',
  SC2 = 'SC2',
}

const assets: Record<symbols, string> = {
  [symbols.A]: pirate_a,
  [symbols.B]: pirate_b,
  [symbols.C]: pirate_c,
  [symbols.D]: gun,
  [symbols.E]: cutlass,
  [symbols.F]: compass,
  [symbols.G]: telescope,
  [symbols.SC1]: scatter,
  [symbols.SC2]: scatter,
  [symbols.WL]: wild,
};

const config: IGameConfig = {
  REELS_COUNT: 5,
  SYMBOLS_IN_REEL: 3,
  SYMBOL_WIDTH: 96,
  SYMBOL_HEIGHT: 96,
  symbols,
  assets,
};

export default config;
