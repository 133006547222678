import type { PropsWithChildren } from 'react';
import Box from '@mui/material/Box';

export const CenterBox = ({ children }: PropsWithChildren) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      {children}
    </Box>
  );
};

export default CenterBox;
