import type { IGameConfig } from 'types';
import cave from './assets/cave.png';
import low1 from './assets/low_1.png';
import low2 from './assets/low_2.png';
import low3 from './assets/low_3.png';
import low4 from './assets/low_4.png';
import mid1 from './assets/mid_1.png';
import mid2 from './assets/mid_2.png';
import mid3 from './assets/mid_3.png';
import mid4 from './assets/mid_4.png';
import top1 from './assets/top_1.png';
import top2 from './assets/top_2.png';
import top3 from './assets/top_3.png';
import top4 from './assets/top_4.png';
import wild2 from './assets/wild_x2.png';
import wild3 from './assets/wild_x3.png';
import wild5 from './assets/wild_x5.png';

enum symbols {
  Wild2 = 'WL1',
  Wild3 = 'WL2',
  Wild5 = 'WL3',
  Top1 = 'A',
  Top2 = 'B',
  Top3 = 'C',
  Top4 = 'D',
  Mid1 = 'E',
  Mid2 = 'G',
  Mid3 = 'F',
  Mid4 = 'H',
  Low1 = 'L',
  Low2 = 'I',
  Low3 = 'K',
  Low4 = 'J',
  Cave = 'MS1',
}

const assets: Record<symbols, string> = {
  [symbols.Wild2]: wild2,
  [symbols.Wild3]: wild3,
  [symbols.Wild5]: wild5,
  [symbols.Top1]: top1,
  [symbols.Top2]: top2,
  [symbols.Top3]: top3,
  [symbols.Top4]: top4,
  [symbols.Mid1]: mid1,
  [symbols.Mid2]: mid2,
  [symbols.Mid3]: mid3,
  [symbols.Mid4]: mid4,
  [symbols.Low1]: low1,
  [symbols.Low2]: low2,
  [symbols.Low3]: low3,
  [symbols.Low4]: low4,
  [symbols.Cave]: cave,
};

const config: IGameConfig = {
  REELS_COUNT: 5,
  SYMBOLS_IN_REEL: 3,
  SYMBOL_WIDTH: 96,
  SYMBOL_HEIGHT: 96,
  symbols,
  assets,
};

export default config;
