import { useMemo, type ReactNode } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import type { IBet } from 'types';
import { getFormattedMoney } from 'utils';
import guaranteImg from './assets/invincible_icon.png';
import lightingBonusImg from './assets/lightning_bonus_icon.png';
import specialShotImg from './assets/special_shot_bonus_icon.png';
import powerImg from './assets/w_power_icon.png';
import HammerThunderBonus from './HammerThunderBonus';
import config from './hammerThunderConfig';
import type { IHammerThunderFeatures } from './hammerThunderTypes';

interface IProps {
  data: IBet<IHammerThunderFeatures>;
}

export const HammerThunder = ({ data }: IProps) => {
  const { steps, totalWin, totalMultiplier } = useMemo(() => {
    const steps: {
      symbol: string;
      isLost: boolean;
      reward: ReactNode;
    }[] = [];
    let totalMultiplier = 0;
    let totalWin = 0;

    const getReward = (rewardName: string) => {
      if (rewardName) {
        if (rewardName.includes('PAYOUT')) {
          const payout =
            Number(rewardName.split('_')[1]) * data.coinAmount * data.coinValue * data.coinAmountMultiplier;
          totalWin += payout;
          if (totalMultiplier) {
            return (
              <Typography variant="h4">
                {getFormattedMoney(payout, data.currency)} x{totalMultiplier} ={' '}
                {getFormattedMoney(payout * totalMultiplier, data.currency)}
              </Typography>
            );
          }

          return <Typography variant="h4">{getFormattedMoney(payout, data.currency)}</Typography>;
        } else if (rewardName.includes('MULTIPLIER')) {
          const multiplier = Number(rewardName.split('_')[1]);
          totalMultiplier += multiplier;
          return <Typography variant="h4">+x{multiplier}</Typography>;
        } else if (rewardName.includes('GUARANTEE')) {
          const guaranteCount = Number(rewardName.split('_')[1]);
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img src={guaranteImg} style={{ width: 128, height: 128, marginLeft: -25 }} />
              <Typography variant="h4">x{guaranteCount}</Typography>
            </Box>
          );
        } else if (rewardName === 'D_W_POWER') {
          return <img src={powerImg} style={{ width: 128, height: 128, marginLeft: -25 }} />;
        } else if (rewardName === 'LIGHTNING_BONUS') {
          return <img src={lightingBonusImg} style={{ width: 128, height: 128, marginLeft: -25 }} />;
        } else if (rewardName === 'SPECIAL_SHOT') {
          return <img src={specialShotImg} style={{ width: 128, height: 128, marginLeft: -25 }} />;
        }
      }

      return <Typography variant="h4">{getFormattedMoney(0, data.currency)}</Typography>;
    };

    for (const step of data.features.generatedReelSet || []) {
      const reward = step.isLost ? <ClearIcon color="error" fontSize="large" /> : getReward(step.rewardName);

      steps.push({
        symbol: step.iconId,
        isLost: step.isLost,
        reward,
      });

      if (step.isLost) {
        break;
      }
    }

    return { steps, totalWin, totalMultiplier };
  }, [data]);

  const lightningBonusValue = useMemo(() => {
    let multiplier = 1;
    data.features.lightningLotteryResult?.forEach((result) => {
      if (result.value.includes('MULTIPLIER')) {
        multiplier *= Number(result.value.split('_')[1]);
      }
    });
    return multiplier;
  }, [data]);

  const specialShotValue = Number(data.features.specialShotLotteryResult?.value.split('_')[1]) || 0;

  if (lightningBonusValue > 1) {
    return <HammerThunderBonus data={data} multiplier={lightningBonusValue} img={lightingBonusImg} />;
  }

  if (specialShotValue > 0) {
    return <HammerThunderBonus data={data} multiplier={specialShotValue} img={specialShotImg} />;
  }

  return (
    <>
      {steps.map((step, index) => (
        <Box key={index} sx={{ display: 'flex', alignItems: 'center', width: '100%', mb: 2, gap: 2 }}>
          <Typography variant="h4">{index + 1}.</Typography>
          <img src={config.assets[step.symbol]} style={{ width: config.SYMBOL_WIDTH, height: config.SYMBOL_HEIGHT }} />
          <ArrowForwardIcon fontSize="large" />
          {step.reward}
        </Box>
      ))}
      {totalMultiplier > 0 && totalWin > 0 && (
        <>
          <Divider sx={{ width: '100%' }} />
          <Typography variant="h4">{`${getFormattedMoney(totalWin, data.currency)} x ${totalMultiplier} = ${getFormattedMoney(totalWin * totalMultiplier, data.currency)}`}</Typography>
        </>
      )}
    </>
  );
};

export default HammerThunder;
